import {SAVE_ORIGIN, SAVE_LOGIN_URL} from "../../actions";

export type AuthReducerState = {
    origin?: string | null,
    previousOrigin?: string | null,
    loginUrl?: string | null
};
type Action = {type: string, origin?: string | null, loginUrl?: string | null};

const AuthReducer = (previousState: AuthReducerState = {}, {type, origin, loginUrl}: Action): AuthReducerState => {
    if (type === SAVE_ORIGIN) {
        if (origin === null) {
            return {loginUrl: previousState.loginUrl};
        } else if (origin !== previousState.origin) {
            return {...previousState, origin, previousOrigin: previousState.origin};
        }
    }
    if (type === SAVE_LOGIN_URL) {
        return {...previousState, loginUrl: loginUrl || null};
    }
    return previousState;
};

export default AuthReducer;