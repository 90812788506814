import React, {MutableRefObject} from 'react';
import {CrudTypes, useCrudSubscription, useGlobalParam} from "@cuda-react/core";
import {AddUserDirectoryButton} from "./AddUserDirectoryButton";
import apiResources from "../../../../../apiResources";
import {UserDirectory} from '../UserDirectoriesTypes';

interface ConditionallyAddUserDirectoryButtonProps {
    onSelect: (type: UserDirectory['type']) => void;
    refreshRef: MutableRefObject<(() => void) | null>;
}

export const ConditionallyAddUserDirectoryButton: React.FC<ConditionallyAddUserDirectoryButtonProps> = ({onSelect, refreshRef}) => {
    const userData = useGlobalParam("userData")[0];
    const [response, loading, performFetch] = useCrudSubscription(
        CrudTypes.GET,
        userData && userData?.mode !== "demo" ? apiResources.directories : undefined,
        {},
        // polling a huge ammount of time to avoid the built it auto refresh
        {pollInterval: 6000000}
    );

    if (refreshRef) {
        refreshRef.current = performFetch as () => void;
    }

    const userDirectories = response?.data?.content ?? null;
    const isDisabled = userData?.mode !== "demo" && (!userDirectories || userDirectories.length !== 0 || loading);
    return <AddUserDirectoryButton onSelect={onSelect} disabled={isDisabled} />;
};