import React from "react";
import {MenuButton} from "@cuda-react/core";
import {IdentityProvider} from "../IdentityProviderTypes";

interface AddIdentityProviderButtonProps {
    onSelect: (type: IdentityProvider['type']) => () => void;
    disabled?: boolean;
}
const i18nScope = "tesseract.identity.identityProviders";

export const AddIdentityProviderButton: React.FC<AddIdentityProviderButtonProps> = ({onSelect, disabled = false}) => {
    const menuItems = [
        {
            label: `${i18nScope}.add.types.entraid`,
            onClick: onSelect('entraid')
        },
        {
            label: `${i18nScope}.add.types.google`,
            onClick: onSelect('google')
        },
        {
            label: `${i18nScope}.add.types.bcc`,
            onClick: onSelect('bcc')
        },
        {
            label: `${i18nScope}.add.types.oidc`,
            onClick: onSelect('oidc')
        },
        {
            label: `${i18nScope}.add.types.saml`,
            onClick: onSelect('saml')
        },
        {
            label: `${i18nScope}.add.types.email`,
            onClick: onSelect('email')
        },
    ];

    return (
        <MenuButton
            disabled={disabled}
            label="tesseract.identity.identityProviders.createIdentityProvider"
            menuItems={menuItems}
        />
    );
};