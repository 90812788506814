import React from "react";
import {UserDirectoryBCC, BCCEnvironment} from "../../UserDirectoriesTypes";
import {ConnectedForm, SelectInput, getArrayDataContent, useCrudProps} from "@cuda-react/core";
import apiResources from "../../../../../../apiResources";

type UserDirectoryBCCFormProps = {
    directory: UserDirectoryBCC;
    create: boolean;
    id?: string;
    onSubmit: () => void;
    onCancel: () => void;
}

export const UserDirectoryBCCForm: React.FC<UserDirectoryBCCFormProps> = ({
    onSubmit,
    onCancel,
    create,
    directory,
    id,
}: UserDirectoryBCCFormProps) => {
    const directoryEnvironmentsData = getArrayDataContent(useCrudProps(apiResources.identityBCCEnvironments));
    const directoryEnvironments = directoryEnvironmentsData[0]?.data?.content;

    return (
        <ConnectedForm
            create={create}
            resource={apiResources.directories}
            params={create ? undefined : {id}}
            onSubmitSuccess={onSubmit}
            onCancel={onCancel}
            initialValues={directory}
            formatRequestData={(data) => ({
                    ...data,
                    displayName: directoryEnvironments.find((directoryEnvironment: BCCEnvironment) => directoryEnvironment.id === data.options.environmentId)?.name,
                } as UserDirectoryBCC)}
        >
            <SelectInput
                source="options.environmentId"
                label="tesseract.identity.userDirectories.form.bcc.environment"
                optionValue="id"
                choices={directoryEnvironments}
                emptyLabel="tesseract.identity.userDirectories.form.bcc.environment"
            />
        </ConnectedForm>
    );
};