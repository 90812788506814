import {
    CrudTypes,
    formatDateTime,
    getArrayDataContent,
    INFO_NOTIFICATION,
    notificationShowBanner,
    useCrudFetch,
    useCrudSubscription,
    useDeepCompareEffect,
    useGlobalParam,
    usePrevious
} from "@cuda-react/core";
import {DAY_AS_MILLISECONDS} from "../utils/dateTime";
import {useDispatch, useSelector} from "react-redux";
import {get} from "lodash";
import apiResources from "../apiResources";


export default () => {
    const dispatch = useDispatch();
    const activeNotifications: (string | number)[] = useSelector((store) => get(store, "NotificationReducer.CUDA/BANNER_NOTIFICATION", []).map((notification) => get(notification, "translateParams.id")).filter((id) => !!id));
    const subscriptionActive = useGlobalParam("userData.subscription.licensed")[0];
    const [updatesUnacknowledged] = useCrudSubscription(CrudTypes.GET, subscriptionActive && apiResources.updatesUnacknowledged, {filter: {acknowledged: false}}, {
        pollInterval: DAY_AS_MILLISECONDS,
        crudOptions: {quietErrors: true}
    });
    const [, , acknowledge] = useCrudFetch(CrudTypes.CREATE, apiResources.updatesAcknowledge, {}, {quietErrors: true});
    const updatesNotifications = getArrayDataContent(updatesUnacknowledged).map((update) => notificationShowBanner(
        "tesseract.statusBanner.updateAvailable",
        INFO_NOTIFICATION,
        true,
        false,
        {
            name: update.name,
            date: formatDateTime(update.mustInstallBy, "MMMM d, yyyy"),
            id: update.id
        }
    ));

    // Display all update notifications
    useDeepCompareEffect(() => {
        updatesNotifications.forEach((updateNotification) => {
            dispatch(updateNotification);
        });
    }, [updatesNotifications]);

    // If a notification has been dismissed (no longer active), send acknowledgement to server.
    const previousNotifications: (string | number)[] | undefined = usePrevious(activeNotifications || []);
    useDeepCompareEffect(() => {
        if (previousNotifications && previousNotifications.length > 0) {
            previousNotifications
                .filter((notificationId) => !activeNotifications.includes(notificationId))
                .forEach((notificationId) => acknowledge({id: notificationId}));
        }
    }, [activeNotifications]);
};