import React, {useMemo} from "react";
import {UserDirectoryLDAP} from "../../UserDirectoriesTypes";
import {
    BooleanInput,
    ConnectedWizard,
    FileInput,
    InputStep,
    SelectInput,
    SubmitStep,
    TextInput,
    usePreview
} from "@cuda-react/core";
import apiResources from "../../../../../../apiResources";
import {get, unset} from "lodash";
import {ConnectorIPsInfo} from './components/ConnectorIPsInfo';

const i18nScope = "tesseract.identity.userDirectories.form";

const isNotLDAPSimpleAuth = (value: any, {options: {ldapAuthMethod}}: any) =>
    ldapAuthMethod !== "simple";

const isNotSasl = (value: any, {options: {ldapAuthMethod}}: any) =>
    !["saslExternal", "saslKerberos"].includes(ldapAuthMethod);

const isNotTls = (value: any, {options: {encryptionMethod}}: any) =>
    !['tls', 'startTls'].includes(encryptionMethod);

const isNotTlsChoice = (value: any, {options: {encryptionMethod}}: any) =>
    !['tls'].includes(encryptionMethod);

type UserDirectoryLdapFormProps = {
    directory: UserDirectoryLDAP;
    create: boolean;
    id?: string;
    onSubmit: () => void;
    onCancel: () => void;
}

const formatRequestData = (data: any): UserDirectoryLDAP => {
    const encryptionMethod: 'none' | 'startTls' | 'tls' = data.options.encryptionMethod;
    const ldapUseTls = encryptionMethod === 'tls';
    const ldapUseStarttls = encryptionMethod === 'startTls';

    const dataToSend = {...data};
    unset(dataToSend, 'options.encryptionMethod');

    return ({
        ...dataToSend,
        options: {
            ...dataToSend.options,
            ldapUseTls,
            ldapUseStarttls,
            ldapPrivkey: get(dataToSend.options, "ldapPrivkey.data", undefined),
            ldapPubkey: get(dataToSend.options, "ldapPubkey.data", undefined),
            ldapCacerts: get(dataToSend.options, "ldapCacerts.data", undefined),
        }
    });
};

export const UserDirectoryLdapForm: React.FC<UserDirectoryLdapFormProps> = ({
    onSubmit,
    onCancel,
    create,
    directory,
    id,
}: UserDirectoryLdapFormProps) => {
    const initialValue = useMemo(() => ({
        ...directory,
        options: {
            ...directory.options,
            encryptionMethod: directory.options.ldapUseTls ? 'tls' : directory.options.ldapUseStarttls ? 'startTls' : 'none'
        }
    }), [directory]);
    const ldapAuthExtended = usePreview("ldapextended");

    return (
        <ConnectedWizard
            resource={apiResources.directories}
            onSubmitSuccess={onSubmit}
            onCancel={onCancel}
            initialValues={initialValue}
            formatRequestData={formatRequestData}
        >
            <InputStep label={`${i18nScope}.ldap.steps.userDirectory`}>
                <ConnectorIPsInfo />
                <TextInput
                    source="displayName"
                    label={`${i18nScope}.displayName`}
                    isRequired />
                <TextInput
                    source="options.ldapHost"
                    label={`${i18nScope}.ldap.host.label`}
                    placeholder={`${i18nScope}.ldap.host.placeholder`}
                    isRequired />
                <TextInput
                    source="options.ldapPort"
                    label={`${i18nScope}.ldap.port.label`}
                    placeholder={`${i18nScope}.ldap.port.placeholder`}
                    isRequired />
                <TextInput
                    source="options.ldapUserSearchBase"
                    label={`${i18nScope}.ldap.userSearchBase.label`}
                    placeholder={`${i18nScope}.ldap.userSearchBase.placeholder`}
                />
                <TextInput
                    source="options.ldapGroupSearchBase"
                    label={`${i18nScope}.ldap.groupSearchBase.label`}
                    placeholder={`${i18nScope}.ldap.groupSearchBase.placeholder`}
                />
            </InputStep>
            <InputStep label={`${i18nScope}.ldap.steps.auth`}>
                <SelectInput
                    source="options.ldapAuthMethod"
                    label={`${i18nScope}.ldap.authenticationMethod.label`}
                    choices={ldapAuthExtended ? [
                        {
                            name: `${i18nScope}.ldap.authenticationMethod.simple`,
                            key: "simple"
                        },
                        {
                            name: `${i18nScope}.ldap.authenticationMethod.anon`,
                            key: "anon"
                        },
                        {
                            name: `${i18nScope}.ldap.authenticationMethod.ntlm`,
                            key: "ntlm"
                        },
                        {
                            name: `${i18nScope}.ldap.authenticationMethod.saslExternal`,
                            key: "saslExternal"
                        },
                        {
                            name: `${i18nScope}.ldap.authenticationMethod.saslKerberos`,
                            key: "saslKerberos"
                        }
                    ] : [
                        {
                            name: `${i18nScope}.ldap.authenticationMethod.simple`,
                            key: "simple"
                        }
                    ]}
                />
                <TextInput
                    source="options.ldapAuthUsername"
                    label={`${i18nScope}.ldap.username`}
                    isRequired
                    hide={isNotLDAPSimpleAuth}
                />
                <TextInput
                    source="options.ldapAuthPassword"
                    type="password"
                    isRequired
                    label={`${i18nScope}.ldap.password`}
                    hide={isNotLDAPSimpleAuth}
                />
                <TextInput
                    source="options.ldapAuthSaslCredentials"
                    label={`${i18nScope}.ldap.saslCredentials`}
                    hide={isNotSasl}
                    isRequired
                />
            </InputStep>
            <SubmitStep label={`${i18nScope}.ldap.steps.tls`}>
                <SelectInput
                    source="options.encryptionMethod"
                    label={`${i18nScope}.ldap.useTls.encryptionMethod`}
                    choices={[
                        {
                            name: `${i18nScope}.ldap.useTls.none`,
                            key: "none"
                        },
                        {
                            name: `${i18nScope}.ldap.useTls.startTls`,
                            key: "startTls"
                        },
                        {
                            name: `${i18nScope}.ldap.useTls.tls`,
                            key: "tls"
                        },
                    ]}
                />
                {ldapAuthExtended ?
                    <TextInput
                        source="options.ldapSni"
                        label={`${i18nScope}.ldap.useTls.ldapSni.label`}
                        placeholder={`${i18nScope}.ldap.useTls.ldapSni.placeholder`}
                        hide={isNotTls}
                    /> : null}
                {ldapAuthExtended ?
                    <FileInput
                        source="options.ldapPrivkey"
                        label={`${i18nScope}.ldap.useTls.ldapPrivkey.label`}
                        hide={isNotTls}
                        readType="string"
                    /> : null}
                {ldapAuthExtended ?
                    <TextInput
                        source="options.ldapPrivkeyPassword"
                        label={`${i18nScope}.ldap.useTls.ldapPrivkeyPassword`}
                        hide={isNotTls}
                    /> : null}
                {ldapAuthExtended ?
                    <FileInput
                        source="options.ldapPubkey"
                        label={`${i18nScope}.ldap.useTls.ldapPubkey.label`}
                        hide={isNotTls}
                        readType="string"
                    /> : null}
                {ldapAuthExtended ?
                    <FileInput
                        source="options.ldapCacerts"
                        label={`${i18nScope}.ldap.useTls.ldapCacerts.label`}
                        hide={isNotTls}
                        readType="string"
                    /> :
                    <FileInput
                        source="options.ldapCacerts"
                        label={`${i18nScope}.ldap.useTls.ldapCacerts.label`}
                        hide={isNotTlsChoice}
                        readType="string"
                    />}
                {ldapAuthExtended ?
                    <BooleanInput
                        source="options.ldapCheckCerts"
                        label={`${i18nScope}.ldap.useTls.ldapCheckCerts.label`}
                        hide={isNotTls}
                    /> : null}
                {ldapAuthExtended ?
                    <BooleanInput
                        source="options.ldapCheckHostname"
                        label={`${i18nScope}.ldap.useTls.ldapCheckHostname.label`}
                        hide={isNotTls}
                    /> : null}
                {ldapAuthExtended ?
                    <TextInput
                        source="options.ldapCertAdditionalNames"
                        label={`${i18nScope}.ldap.useTls.ldapCertAdditionalNames.label`}
                        placeholder={`${i18nScope}.ldap.useTls.ldapCertAdditionalNames.placeholder`}
                        hide={isNotTls}
                    /> : null}
            </SubmitStep>

        </ConnectedWizard>
    );
};