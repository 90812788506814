import React from "react";
import {ConnectedForm, DialogBody, TextInput} from "@cuda-react/core";
import apiResources from "../../../../../../apiResources";
import {IdentityProvider} from "../../IdentityProviderTypes";
import {IdentityProviderSAMLForm} from "./IdentityProviderSAMLForm";
import {RedirectUriInfo} from './RedirectUriInfo';

type IdentityProviderFormProps = {
    identityProvider: IdentityProvider;
    create: boolean;
    id?: string;
    onClose: (refresh: boolean) => void;
}

const i18nScope = "tesseract.identity.identityProviders.add";

export const IdentityProviderForm: React.FC<IdentityProviderFormProps> = ({
    onClose,
    create,
    identityProvider,
    id,
}: IdentityProviderFormProps) => {
    if (identityProvider.type === 'saml') {
        return (
            <IdentityProviderSAMLForm
                onSubmit={() => onClose(true)}
                onCancel={() => onClose(false)}
                create={create}
                identityProvider={identityProvider}
                id={id}
            />
        );
    }
    return (
        <DialogBody
            title={`tesseract.identity.identityProviders.createIdentityProvider`}
            onClose={() => onClose(false)}
            form
        >
            <ConnectedForm
                create={create}
                resource={apiResources.identityProvider}
                params={create ? undefined : {id}}
                onSubmitSuccess={() => onClose(true)}
                onCancel={() => onClose(false)}
                initialValues={identityProvider}
            >
                <TextInput
                    source="displayName"
                    label={`${i18nScope}.options.displayName`}
                    isRequired />

                {/* EntraID */}
                <TextInput
                    source="options.tenantId"
                    label={`${i18nScope}.options.tenantId`}
                    hide={(value: any, idp) => idp?.type !== 'entraid'}
                    isRequired />

                {/* OIDC */}
                <TextInput
                    source="options.discoveryEndpoint"
                    label={`${i18nScope}.options.discoveryURL`}
                    hide={(value: any, idp) => idp?.type !== 'oidc'}
                    isRequired />
                <TextInput
                    source="options.clientId"
                    label={`${i18nScope}.options.clientId`}
                    hide={(value: any, idp) => idp?.type !== 'oidc'}
                    isRequired />
                <TextInput
                    source="options.clientSecret"
                    label={`${i18nScope}.options.clientSecret`}
                    hide={(value: any, idp) => idp?.type !== 'oidc'}
                    isRequired />
                <TextInput
                    source="options.scope"
                    label={`${i18nScope}.options.scope`}
                    hide={(value: any, idp) => idp?.type !== 'oidc'}
                    isRequired />

                {/* Google */}
                <TextInput
                    source="options.googleWorkspaceDomain"
                    label={`${i18nScope}.options.googleWorkspaceDomain`}
                    hide={(value: any, idp) => idp?.type !== 'google'}
                    isRequired />
                {
                    identityProvider?.type === 'oidc' ? (
                        <RedirectUriInfo />
                    ) : null
                }
            </ConnectedForm>
        </DialogBody>
    );
};