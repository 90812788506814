import React, {useCallback, useRef, useState} from "react";
import {
    ActionButtonsField,
    Card,
    ConnectedTable,
    ConnectedTableRefresh,
    CustomField,
    DeleteDialog,
    DialogBody,
    TextField,
    useMixpanel
} from "@cuda-react/core";
import apiResources from "../../../../apiResources";
import {useTranslation} from "react-i18next";
import {CreatedUserDirectory, UserDirectory, UserDirectoryType} from "./UserDirectoriesTypes";
import {UserDirectorySyncResultField} from "./components/UserDirectorySyncResultField";
import {UserDirectoryStatusField} from "./components/UserDirectoryStatusField";
import CreateEditButtonDialog from "../../../../components/CreateEditButtonDialog";
import {Dialog} from "@barracuda-internal/bds-core";
import {UserDirectoryForm} from "./components/UserDirectoryForm/UserDirectoryForm";
import {EditUserDirectoryWrapper} from "./components/EditUserDirectoryWrapper";
import {UserDirectoryMenu} from "./components/UserDirectoryMenu";
import {LastSyncField} from "./components/LastSyncField";
import {styled} from "@mui/material";
import {ConditionallyAddUserDirectoryButton} from "./components/ConditionallyAddUserDirectoryButton";
import {UserDirectoriesNoData} from "./components/UserDirectoriesNoData";
import useDemoApi from "../../../../hooks/useDemoApi";

const showEditDialog = false;

const initialValue = {
    displayName: "",
    type: "bcc",
    accountName: "",
};

const initialOptions: Record<UserDirectoryType, UserDirectory['options']> = {
    ldap: {
        ldapAuthMethod: "simple",
        ldapAuthPassword: "",
        ldapAuthUsername: "",
        ldapCacerts: "",
        ldapCertAdditionalNames: "",
        ldapCheckCerts: false,
        ldapCheckHostname: false,
        ldapGroupSearchBase: "",
        ldapHost: "",
        ldapPort: "",
        ldapPrivkey: "",
        ldapPrivkeyPassword: "",
        ldapProfile: "ad",
        ldapPubkey: "",
        ldapSni: "",
        ldapUseStarttls: false,
        ldapUseTls: false,
        ldapUserSearchBase: "",
    },
    okta: {
        oktaAuthToken: "",
        oktaDomainName: "",
    },
    google: {
        googleWorkspaceDomain: "",
    },
    entraid: {
        tenantId: "",
    },
    bcc: {
        environmentId: "",
    }
};

const i18nScope = "tesseract.identity.userDirectories";

const MinHeightCard = styled(Card)({
    height: 'calc(50vh - 90px)',
});

const DeleteContent = styled("div")({
    maxWidth: '600px',
    width: "100%"
});

export const UserDirectories = () => {
    const directoryApi = useDemoApi(apiResources.directories, apiResources.demoDirectories);
    const registerAction = useMixpanel('User Directories');
    const tableRefreshRef = useRef<ConnectedTableRefresh | null>(null);
    const addButtonRefreshRef = useRef<() => void>(null);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [initialFormValue, setInitialFormValue] = useState<UserDirectory>(
        {
            ...initialValue,
            type: "bcc",
            options: {
                environmentId: "",
            }
        }
    );

    const [translate] = useTranslation();

    const handleCreateClose = (wasCreated: boolean) => {
        if (wasCreated) {
            registerAction('created');
            tableRefreshRef.current?.();
            addButtonRefreshRef.current?.();
        }
        setDialogOpen(false);
    };

    const handleOpenForm = (type: UserDirectoryType) => {
        const newUserDirectory = {
            ...initialValue,
            type,
            options: {
                ...initialOptions[type]
            }
        } as UserDirectory;

        setInitialFormValue(newUserDirectory);
        setDialogOpen(true);
    };

    const handleDelete = useCallback(() => {
        registerAction('deleted');
        tableRefreshRef.current?.();
        addButtonRefreshRef.current?.();
    }, []);

    return (
        <MinHeightCard title={`${i18nScope}.subtitle`}>
            <ConnectedTable
                hasTitle
                resource={directoryApi}
                noDataPage={<UserDirectoriesNoData onSelect={handleOpenForm} />}
                tableName={`${i18nScope}.tableName`}
                minCellWidth={142}
                pollInterval={300000}
                refreshRef={tableRefreshRef}
                actions={[
                    <ConditionallyAddUserDirectoryButton
                        key={0}
                        onSelect={handleOpenForm}
                        refreshRef={addButtonRefreshRef}
                    />
                ]}
            >
                <TextField
                    source="displayName"
                    label={`${i18nScope}.displayName`}
                    sortable />
                <CustomField
                    source="type"
                    label={`${i18nScope}.type`}
                    render={(value: any, directory: CreatedUserDirectory) => translate(`${i18nScope}.form.type.${directory.type}`)}
                />
                <CustomField
                    source="connectionStatus"
                    label={`${i18nScope}.status`}
                    render={(value: any, directory: CreatedUserDirectory) => <UserDirectoryStatusField directory={directory} />}
                />
                <CustomField
                    source="lastSuccessfulSync"
                    label={`${i18nScope}.lastSuccessfulSync`}
                    render={(value: any, directory: CreatedUserDirectory) => <LastSyncField directory={directory} />}
                />
                <CustomField
                    label={`${i18nScope}.syncResult`}
                    source="totalUsers"
                    render={(value: any, directory: CreatedUserDirectory) => <UserDirectorySyncResultField directory={directory} />}
                />
                <ActionButtonsField
                    width={142}
                    source="id"
                    alwaysVisible
                >
                    <UserDirectoryMenu />
                    {showEditDialog ? (<CreateEditButtonDialog
                        create={false}
                        component={EditUserDirectoryWrapper}
                        onSuccess={() => {
                            tableRefreshRef.current?.();
                            addButtonRefreshRef.current?.();
                        }}
                    />) : null}
                    <DeleteDialog
                        resource={apiResources.directories}
                        title="tesseract.identity.userDirectories.delete.title"
                        message={
                            <DeleteContent>
                                <p>
                                    {translate("tesseract.identity.userDirectories.delete.body")}
                                </p>
                                <p>
                                    {translate("tesseract.identity.userDirectories.delete.body2")}
                                </p>
                            </DeleteContent>
                        }
                        onSuccess={handleDelete}
                    />
                </ActionButtonsField>
            </ConnectedTable>
            <Dialog
                open={dialogOpen}
                maxWidth={false}
            >
                <DialogBody
                    form
                    title={`${i18nScope}.createDirectoryDialogTitle`}
                    onClose={() => handleCreateClose(false)}
                >
                    <UserDirectoryForm
                        create={true}
                        directory={initialFormValue}
                        onSubmit={() => handleCreateClose(true)}
                        onCancel={() => handleCreateClose(false)}
                    />
                </DialogBody>
            </Dialog>
        </MinHeightCard>
    );
};