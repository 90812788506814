import {BooleanInput, ConnectedForm, useGlobalParam, useMixpanel} from "@cuda-react/core";
import React from "react";
import apiResources from "../../../apiResources";

interface EditCloudGatewayContentProps {
    id: number | string;
    details: {
        gatewayType?: "cloud" | "on-prem" | "managed";
        model?: string;
    };
}

const EditCloudGatewayContent: React.FC<EditCloudGatewayContentProps> = ({id, details: {model, gatewayType}}) => {
    const registerAction = useMixpanel(
        "Gateways Settings",
        !!model,
        {model: model || "", type: gatewayType || ""}
    );
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");

    return (
        <ConnectedForm
            resource={apiResources.cloudGateways}
            id={id}
            topToolbar
            formatRequestData={(data) => ({
                ...data,
                id,
                virtualWanId
            })}
            onSubmitSuccess={(response) => {
                registerAction("Update", {
                    model: response.model,
                    type: "cloud"
                });
            }}
        >
            <BooleanInput
                source="securePeeredNetworks"
                label="tesseract.gateways.settings.labels.secureInternetTraffic"
                description="tesseract.gateways.settings.descriptions.secureInternetTraffic"
            />
            <BooleanInput
                source="disableSyslogStreaming"
                label="tesseract.syslog.syslogOverride"
                description="tesseract.syslog.descriptions.syslogOverride_gateway"
            />
        </ConnectedForm>
    );
};

export default EditCloudGatewayContent;