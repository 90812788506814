import {all, call} from "redux-saga/effects";
// @ts-ignore
import AuthSagas from "./AuthSagas";
import RedirectSaga from "./RedirectSaga";

export default (authClient: Function | null) => (function* rootSaga() {
    yield all([
        call(AuthSagas(authClient)),
        call(RedirectSaga)
    ]);
});

export {AuthSagas, RedirectSaga};