import React from "react";
import {UserDirectoryEntraId} from "../../UserDirectoriesTypes";
import {ConnectedForm, TextInput} from "@cuda-react/core";
import apiResources from "../../../../../../apiResources";

const i18nScope = "tesseract.identity.userDirectories.form";

type UserDirectoryEntraIDFormProps = {
    directory: UserDirectoryEntraId;
    create: boolean;
    id?: string;
    onSubmit: () => void;
    onCancel: () => void;
}

export const UserDirectoryEntraIDForm: React.FC<UserDirectoryEntraIDFormProps> = ({
    onSubmit,
    onCancel,
    create,
    directory,
    id,
}: UserDirectoryEntraIDFormProps) => (
        <ConnectedForm
            create={create}
            resource={apiResources.directories}
            params={create ? undefined : {id}}
            onSubmitSuccess={() => { onSubmit(); }}
            onCancel={onCancel}
            initialValues={directory}
        >
            <TextInput
                source="displayName"
                label={`${i18nScope}.displayName`}
                isRequired
            />
            <TextInput
                source="options.tenantId"
                label={`${i18nScope}.tenantId`}
                isRequired
            />
        </ConnectedForm>
    );