import {merge} from "lodash";
import {SET_GLOBAL_PARAMS, UPDATE_GLOBAL_PARAMS} from "../../actions";

export type GlobalParamsReducerState = {[key: string]: any};
export type Action = {type: string, params?: {[key: string]: any}};

const GlobalParamsReducer = (previousState: GlobalParamsReducerState = {}, {type, params}: Action): GlobalParamsReducerState => {
    switch (type) {
        case SET_GLOBAL_PARAMS:
            return Object.assign(
                {},
                params
            );
        case UPDATE_GLOBAL_PARAMS:
            return merge(
                {},
                previousState,
                params
            );
        default:
            return previousState;
    }
};

export default GlobalParamsReducer;