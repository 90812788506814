import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {authLogin} from "../../../actions";
// @ts-ignore
import {parse} from "query-string";
import {useLocation} from "react-router";
import AuthenticatingPage from "../AuthenticatingPage/AuthenticatingPage";

/**
 * Login page to use alongside the BccRedirectAuthClient.
 *
 * This is the default page at '/#/login' when using CudaReactApp with bccAuthentication.
 * It renders an [AuthenticatingPage](/?path=/docs/authentication-authenticatingpage), and triggers an
 * [AUTH_LOGIN](/?path=/docs/cudareactapp-authentication--page) action after render.
 *
 * There are no props for this component.
 */
export const BccRedirectLoginPage = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const queryString = parse(location.search);

    useEffect(() => {
        dispatch(authLogin(queryString));
    }, []);

    return (
        <AuthenticatingPage />
    );
};

export default BccRedirectLoginPage;