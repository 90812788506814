import React from "react";
import {
    BooleanInput,
    getArrayDataContent,
    MultiInput,
    SelectInput,
    TextInput,
    useCrudProps,
    useDeepCompareMemo
} from "@cuda-react/core";
import {makeStyles} from "@mui/styles";
import apiResources from "../../../../../apiResources";
import {get} from "lodash";
import {useWatch} from "react-hook-form";

const useStyles = makeStyles({
    appMinimumVersion: {
        paddingLeft: 32
    },
    osPlatform: {
        paddingLeft: 32
    },
    osMinimumVersion: {
        paddingLeft: 0
    },
    input: {
        width: 128
    },
    multiInputGroup: {
        minHeight: 50
    }
});

export const defaultDeviceAttributes = {
    deviceCriteria: {
        requireScreenLock: true,
        requireFirewall: true,
        requireNotJailbroken: true,
        requireDiskEncryption: false,
        requireAntivirus: true
    }
};

const validatePlatforms = (value: any, data: any, {t: translate}: any) =>
    get(data, "deviceCriteria.osMinimumVersions", [])
        .map(({platform}: any) => platform)
        .filter((platform: any) => value === platform)
        .length > 1 ? translate("tesseract.endpoint.remoteAccessPolicies.validatePlatform") : undefined;

export const validateDeviceAttributes = (translate: any) => (data: any) => {
    const error = get(data, "accessCriteria.securityPosture", "disable") !== "disable" && !(
        get(data, "deviceCriteria.requireScreenLock") ||
        get(data, "deviceCriteria.requireFirewall") ||
        get(data, "deviceCriteria.requireNotJailbroken") ||
        get(data, "deviceCriteria.requireDiskEncryption") ||
        get(data, "deviceCriteria.appUpdates") ||
        get(data, "deviceCriteria.osUpdates") ||
        get(data, "deviceCriteria.requireAntivirus")
    ) ? translate("tesseract.endpoint.remoteAccessPolicies.validateDeviceCriteria") : undefined;
    return error ? {
        deviceCriteria: {
            requireScreenLock: error,
            requireFirewall: error,
            requireNotJailbroken: error,
            requireDiskEncryption: error,
            appUpdates: error,
            osUpdates: error,
            requireAntivirus: error,
        }
    } : {};
};

export const DeviceAttributesInputs = () => {
    const platformChoices = getArrayDataContent(useCrudProps(apiResources.zeroTrustPoliciesOperatingSystem)[0].data);
    const classes = useStyles();
    const activePlatformChoices = (useWatch({name: "deviceCriteria.osMinimumVersions"}) || []).map(({platform}: any) => platform);
    const nextPlatformDefault = useDeepCompareMemo(
        // I have no idea why the last entry needs to be sliced off. It seems like somehow the activePlatformChoices gets the selected defaultChoice prior to the Select
        // component stabilising, so you need to ignore that last value, otherwise it will skip it.
        () => platformChoices.find((choice) => !activePlatformChoices.slice(0, activePlatformChoices.length - 1).includes(choice.key))?.key,
        [activePlatformChoices, platformChoices]
    );

    return (
        <React.Fragment>
            <BooleanInput
                label="tesseract.endpoint.remoteAccessPolicies.screenLock"
                description="tesseract.endpoint.remoteAccessPolicies.descriptions.screenLock"
                source="deviceCriteria.requireScreenLock"
            />
            <BooleanInput
                label="tesseract.endpoint.remoteAccessPolicies.firewall"
                description="tesseract.endpoint.remoteAccessPolicies.descriptions.firewall"
                source="deviceCriteria.requireFirewall"
            />
            <BooleanInput
                label="tesseract.endpoint.remoteAccessPolicies.antivirus"
                description="tesseract.endpoint.remoteAccessPolicies.descriptions.antivirus"
                source="deviceCriteria.requireAntivirus"
            />
            <BooleanInput
                label="tesseract.endpoint.remoteAccessPolicies.jailbroken"
                description="tesseract.endpoint.remoteAccessPolicies.descriptions.jailbroken"
                source="deviceCriteria.requireNotJailbroken"
            />
            <BooleanInput
                label="tesseract.endpoint.remoteAccessPolicies.encryption"
                description="tesseract.endpoint.remoteAccessPolicies.descriptions.encryption"
                source="deviceCriteria.requireDiskEncryption"
            />
            <BooleanInput
                label="tesseract.endpoint.remoteAccessPolicies.appUpdates"
                description="tesseract.endpoint.remoteAccessPolicies.descriptions.appUpdates"
                source="deviceCriteria.appUpdates"
            />
            <TextInput
                label="tesseract.endpoint.remoteAccessPolicies.minimumVersion"
                source="deviceCriteria.appMinimumVersion"
                hide={(value, data) => !get(data, "deviceCriteria.appUpdates")}
                inputLabelProps={{minimised: true, classes: {root: classes.appMinimumVersion}}}
                inputClasses={{textField: classes.input}}
                isRequired
            />
            <BooleanInput
                label="tesseract.endpoint.remoteAccessPolicies.osUpdates"
                description="tesseract.endpoint.remoteAccessPolicies.descriptions.osUpdates"
                source="deviceCriteria.osUpdates"
            />
            <MultiInput
                source="deviceCriteria.osMinimumVersions"
                hide={(value, data) => !get(data, "deviceCriteria.osUpdates")}
                classes={{inputGroup: classes.multiInputGroup}}
                maxInputs={4}
                isRequired
            >
                <SelectInput
                    label="tesseract.endpoint.remoteAccessPolicies.platform"
                    source="platform"
                    choices={platformChoices}
                    inputLabelProps={{minimised: true, classes: {root: classes.osPlatform}}}
                    inputClasses={{select: classes.input, formControl: classes.input}}
                    inputProps={{defaultChoice: nextPlatformDefault}}
                    validate={validatePlatforms}
                    isRequired
                />
                <TextInput
                    label="tesseract.endpoint.remoteAccessPolicies.minimumVersion"
                    source="version"
                    inputLabelProps={{minimised: true, classes: {root: classes.osMinimumVersion}}}
                    inputClasses={{textField: classes.input}}
                    isRequired
                />
            </MultiInput>
        </React.Fragment>
    );
};

export default DeviceAttributesInputs;