import {useDeepCompareEffect, usePersistedState} from "../../../hooks";
import React, {createContext} from "react";
import {useHistory, useLocation} from "react-router";
import {get, uniq} from "lodash";

export type PreviewContextProps = string[];

export const PreviewContext = createContext<PreviewContextProps>([]);

const matcher = /(?:\?|&)preview=([^&|?\s]*)/;
const clearValues = ["none", "clear", "0"];

/**
 * Preview Provider. Provides the ability to monitor active "preview" modes, that are set using a query in the url.
 *
 * Add '?preview=somename' to turn on the "somename" preview.
 *
 * You can turn on multiple at once, either by repetitively adding new queries and refreshing, or via a single url query
 * with the values comma seperated, e.g.: '?preview=somename,anothername,foobar'
 *
 * To disable all active previews use:
 *
 * '?preview=none' or '?preview=0'.
 *
 * In code, to check whether a particular preview is active, use the "usePreview" hook, e.g.:
 *
 *     'const previewActive = usePreview("somename");'
 *
 * @param children
 * @constructor
 */
export const PreviewProvider = ({children}: {children?: React.ReactNode}) => {
    const [activePreviews = [], setActivePreviews] = usePersistedState<string[]>("activePreviews", []);
    const location = useLocation();
    const {push} = useHistory();

    useDeepCompareEffect(() => {
        const search = get(location, "search", "");
        const hash = get(location, "hash", "");
        const match = search.match(matcher) || hash.match(matcher);

        if (match && match[1]) {
            push({
                ...location,
                search: search.replace(match[0], ""),
                hash: hash.replace(match[0], "")
            });

            if (clearValues.includes(match[1])) {
                setActivePreviews([]);
            } else {
                const previews = match[1].split(",");
                setActivePreviews(uniq([...activePreviews, ...previews]));
            }
        }
    }, [location]);

    return (
        <PreviewContext.Provider value={activePreviews}>
            {children}
        </PreviewContext.Provider>
    );
};

export default PreviewProvider;