import React from "react";
import {MultiInput, TextInput, validateDomain, validateMaxLengthMemo, validateRegexMemo} from "@cuda-react/core";
import {useSiteInputValidations} from "../../../../../hooks/createEditSiteHooks";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    multiInputMainColumnStyle: {
        paddingBottom: 18,
        paddingLeft: 16,
        paddingRight: 16
    },
    topBarColumnStyle: {
        marginTop: 0
    }
});

interface SdwanConnectorServicesStepProps {
    create?: boolean,
}

const SdwanConnectorServicesStep: React.FC<SdwanConnectorServicesStepProps> = ({create}) => {
    const classes = useStyles();
    const inputValidations = useSiteInputValidations();

    return (
        <React.Fragment>
            <MultiInput
                maxInputs={250}
                source="resources"
                classes={!create ? classes : undefined}
                columnStyle
                columnStyleItemLabelSource="serviceName"
                columnStyleItemLabel="tesseract.network.sdwanConnector.dialog.servers.itemName"
                columnStyleTitleLabel="tesseract.network.sdwanConnector.dialog.servers.serviceName"
            >
                <TextInput
                    source="serviceName"
                    label="tesseract.network.sdwanConnector.dialog.servers.serviceName"
                    description={"tesseract.network.sdwanConnector.dialog.description.servers.serviceName"}
                    isRequired
                    validate={[validateMaxLengthMemo(31), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")]}
                />
                <TextInput
                    source="hostname"
                    label="tesseract.network.sdwanConnector.dialog.servers.hostname"
                    description={"tesseract.network.sdwanConnector.dialog.description.servers.hostname"}
                    isRequired
                    validate={[validateDomain]}
                />
                <TextInput
                    source="internalIp"
                    label="tesseract.network.sdwanConnector.dialog.servers.internalIp"
                    description="tesseract.network.sdwanConnector.dialog.description.servers.internalIp"
                    validate={inputValidations.validateIp}
                    isRequired
                />
            </MultiInput>
        </React.Fragment>
    );
};

export default SdwanConnectorServicesStep;