import React from "react";

import {TablePage, TextField,} from "@cuda-react/core";
import apiResources from "../../../apiResources";
import useDemoApi from "../../../hooks/useDemoApi";

const i18nScope = 'tesseract.identity';

export const Groups = () => {
    const groupsApi = useDemoApi(apiResources.identityGroups, apiResources.demoGroups);

    return (
        <TablePage
            authenticated
            pageMode="paginate"
            title={`${i18nScope}.pageTitle`}
            subtitle={`${i18nScope}.groups.title`}
            resource={groupsApi}
            noDataMessage={`${i18nScope}.groups.noDataMessage`}
            tableName={`${i18nScope}.groups.tableName`}
            sortable
        >
            <TextField
                source="displayName"
                label={`${i18nScope}.groups.name`}
                filter="text"
            />
        </TablePage>
    );
};