import React from "react";
import {DashboardCardProps, ResourceStatisticsCard, useFetchStatisticsCardDetailsAndTotal} from "@stratos/reporting";
import apiResources from "../../../../apiResources";
import {getStatusColorFromTheme} from "../../../../utils/themeUtils";
import {Theme, useTheme} from "@mui/material";
import {
    ActionButtonsField,
    generateQueryString,
    getArrayDataContent,
    LinkField,
    SelectedChoiceField,
    StatusIconField,
    TextField,
    useCrudProps,
    useGlobalParam
} from "@cuda-react/core";
import {getAzureResourceLink} from "../../../../utils/azureUtils";
import {Forward} from "@barracuda-internal/bds-core/dist/Icons/Core";
import {push} from "react-router-redux";
import {useDispatch} from "react-redux";
import {StatisticsDetails} from "@stratos/reporting/lib/hooks/useFetchStatisticsCardDetailsAndTotal";

export interface GatewayStatusCardProps extends DashboardCardProps {}

const previewData = (theme: Theme) => [
    [
        {
            "title": "tesseract.dashboard.cards.gateway.online",
            "value": 9,
            "color": getStatusColorFromTheme("online", theme)
        },
        {
            "title": "tesseract.dashboard.cards.gateway.pending",
            "value": 0,
            "color": getStatusColorFromTheme("pending", theme)
        },
        {
            "title": "tesseract.dashboard.cards.gateway.degraded",
            "value": 0,
            "color": getStatusColorFromTheme("degraded", theme)
        },
        {
            "title": "tesseract.dashboard.cards.gateway.offline",
            "value": 1,
            "color": getStatusColorFromTheme("offline", theme)
        }
    ],
    16
] as [StatisticsDetails[], number];

const GatewayStatusCard: React.FC<GatewayStatusCardProps> = (props) => {
    const {preview} = props;
    const theme = useTheme();
    const apiResourceData = useFetchStatisticsCardDetailsAndTotal(
        {
            url: preview ? undefined : apiResources.gatewayStatus
        },
        {
            url: apiResources.gatewayStates
        },
        {
            linkCreator: (status: any) => `/#/infrastructure/gateways${generateQueryString({connectionStatus: [status.key]})}`,
            getKeyColor: (key: any) => (getStatusColorFromTheme(key, theme))
        }
    );
    const [staticData, total] = preview ? previewData(theme) : apiResourceData;
    const isDemo = useGlobalParam("userData.mode")[0] === "demo";
    const dispatch = useDispatch();
    const regionChoices = useCrudProps(apiResources.gatewayRegions)[0]?.data;

    return (
        <ResourceStatisticsCard
            {...props}
            overViewData={staticData}
            overViewValue={total}
            overviewTitle="tesseract.dashboard.cards.gateway.cardTitle"
            tableTitle="tesseract.network.gateways"
        >
            <StatusIconField
                source="aggregateState"
                width={80}
            />
            <LinkField
                source="name"
                label="tesseract.gateways.table.name"
                link={getAzureResourceLink("applicationId", isDemo)}
                sortable
            />
            <SelectedChoiceField
                source="region"
                label="tesseract.gateways.table.region"
                choices={getArrayDataContent(regionChoices)}
                sortable
            />
            <TextField
                source="sites"
                label="tesseract.gateways.table.sites"
                width={104}
                sortable
            />
            <ActionButtonsField width={64} source="id" alwaysVisible>
                <Forward
                    id="gateway-dashboard-icon"
                    onClick={(event: any, data: any) => {
                        dispatch(push("/infrastructure/gateways/" + data.id));
                    }}
                />
            </ActionButtonsField>
        </ResourceStatisticsCard>
    );
};


export default GatewayStatusCard;