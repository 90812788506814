import React, {MutableRefObject} from 'react';
import {CrudTypes, useCrudSubscription, useGlobalParam} from "@cuda-react/core";
import {AddIdentityProviderButton} from "./AddIdentityProviderButton";
import apiResources from "../../../../../apiResources";
import {IdentityProvider} from '../IdentityProviderTypes';

interface ConditionallyAddIdentityProviderButtonProps {
    onSelect: (type: IdentityProvider['type']) => () => void;
    refreshRef: MutableRefObject<(() => void) | null>;
}

export const ConditionallyAddIdentityProviderButton: React.FC<ConditionallyAddIdentityProviderButtonProps> = ({onSelect, refreshRef}) => {
    const userData = useGlobalParam("userData")[0];
    const [response, loading, performFetch] = useCrudSubscription(
        CrudTypes.GET,
        userData && userData?.mode !== "demo" ? apiResources.identityProvider : undefined,
        {},
        // polling a huge ammount of time to avoid the built it auto refresh
        {pollInterval: 6000000}
    );

    if (refreshRef) {
        refreshRef.current = performFetch;
    }

    const identityProviders = response?.data?.content ?? null;
    const isDisabled = userData?.mode !== "demo" && (!identityProviders || identityProviders.length !== 0 || loading);

    return <AddIdentityProviderButton onSelect={onSelect} disabled={isDisabled} />;
};