import React from "react";
import {TablePage, TextField,} from "@cuda-react/core";
import apiResources from "../../../apiResources";
import useDemoApi from "../../../hooks/useDemoApi";

export const Users = () => {
    const usersApi = useDemoApi(apiResources.identityUsers, apiResources.demoUsers);

    return (
        <TablePage
            authenticated
            pageMode="paginate"
            title="tesseract.identity.pageTitle"
            subtitle="tesseract.identity.users.title"
            resource={usersApi}
            noDataMessage="tesseract.identity.users.noDataMessage"
            tableName="tesseract.identity.users.tableName"
        >
            <TextField
                source="name"
                label="tesseract.identity.users.name"
                filter="text"
                filterProps={{
                    label: "tesseract.identity.users.nameOrEmail"
                }}
            />
            <TextField
                source="email"
                label="tesseract.identity.users.email"
            />
        </TablePage>
    );
};