import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {
    BANNER_NOTIFICATION,
    DIALOG_NOTIFICATION,
    notificationHide,
    notificationShowBanner,
    notificationShowDialog,
    notificationShowToast,
    TOAST_NOTIFICATION,
    NOTIFICATION_TYPE, NOTIFICATION_LEVEL
} from "../actions";
import {useDeepCompareMemo} from "./UtilHooks";
import {TOptions} from "i18next";

const getValueOrDefault = <T = any>(value: T | undefined, defaultValue: T): T => value !== undefined ? value : defaultValue;

export interface NotificationSettings {
    /** controls if banner notifications are dismissable. */
    dismissible?: boolean;
    /** controls if banner notifications display a spinner. */
    spinner?: boolean;
    /** overrides the default toast show duration. */
    duration?: number;
    /** object to provide to the content i18n translation call. */
    translateParams?: TOptions;
}

/**
 * @callback show
 * @function
 * @param {string} type the notification type to show/hide (default is type provided on the hook invocation).
 * @param {string} content the notification content to use when showing (default is content provided on the hook invocation).
 * @param {string} level the notification level to use when showing (default is level provided on the hook invocation).
 * @param {object} options the additional default notification options to use when showing (defaults are the options provided on the hook invocation).
 * @returns undefined
 */
export type ShowNotificationCallback =  (type?: NOTIFICATION_TYPE, content?: string, level?: NOTIFICATION_LEVEL, options?: NotificationSettings) => void;

/**
 * @callback hide
 * @function
 * @param {string} type The notification type to hide (default is type provided on the hook invocation).
 * @param {string} content the notification content to use when showing (default is content provided on the hook invocation).
 * @returns undefined
 */
export type HideNotificationCallback =  (type?: NOTIFICATION_TYPE, content?: string) => void;

export type UseNotificationsResponse = [
    show: ShowNotificationCallback,
    hide: HideNotificationCallback
];

/**
 * Hook for managing a notification. Provides functions for showing/hiding notifications.
 * The properties for the notification can be provided when invoking the hook, or provided/overriden when calling the provided show/hide callbacks.
 *
 * @function
 * @param {string} defaultType the default notification type to show/hide.
 * @param {string} defaultContent the default notification content to use when showing.
 * @param {string} defaultLevel the default notification level to use when showing.
 * @param {object} defaultOptions additional default notification options to use when showing.
 * @returns {Array.<{show: show,hide: hide}>} an array containing the show callback (which shows a notification with either the default values or provided values) and hide callback (which hides either the default notification type or provided notification type)
 */
export const useNotifications = (defaultType: NOTIFICATION_TYPE, defaultContent: string, defaultLevel: NOTIFICATION_LEVEL, {dismissible, spinner, duration, translateParams}: NotificationSettings = {}): UseNotificationsResponse => {
    const dispatch = useDispatch();
    return useDeepCompareMemo(() => [
        (type, content, level, options = {}) => {
            if (getValueOrDefault(type, defaultType) === BANNER_NOTIFICATION) {
                dispatch(notificationShowBanner(
                    getValueOrDefault(content, defaultContent),
                    getValueOrDefault(level, defaultLevel),
                    getValueOrDefault(options.dismissible, dismissible),
                    getValueOrDefault(options.spinner, spinner),
                    getValueOrDefault(options.translateParams, translateParams)
                ));
            } else if (getValueOrDefault(type, defaultType) === DIALOG_NOTIFICATION) {
                dispatch(notificationShowDialog(
                    getValueOrDefault(content, defaultContent),
                    getValueOrDefault(options.translateParams, translateParams)
                ));
            } else {
                dispatch(notificationShowToast(
                    getValueOrDefault(content, defaultContent),
                    getValueOrDefault(level, defaultLevel),
                    getValueOrDefault(options.duration, duration),
                    getValueOrDefault(options.translateParams, translateParams)
                ));
            }
        },
        (type, content) => dispatch(notificationHide(getValueOrDefault(type, defaultType) || TOAST_NOTIFICATION, getValueOrDefault(content, defaultContent)))
    ], [defaultContent, defaultType, defaultLevel, dismissible, spinner, duration, translateParams]);
};

/**
 * Hook for creating a banner that is always visible when the calling component is visible.
 *
 * Creates a banner notification on first render, and hides it when unmounted.
 *
 * @function
 * @param {string} content the notification content to use when showing (default is content provided on the hook invocation).
 * @param {string} level the notification level to use when showing (default is level provided on the hook invocation).
 * @param {boolean} dismissible controls if banner notifications are dismissable.
 * @param {boolean} spinner controls if banner notifications display a spinner.
 * @param {object} translateParams object to provide to the content i18n translation call.
 */
export const usePageBanner = (content: string, level: NOTIFICATION_LEVEL, dismissible?: boolean, spinner?: boolean, translateParams?: TOptions): void => {
    const [show, hide] = useNotifications(BANNER_NOTIFICATION, content, level, {dismissible, spinner, translateParams});
    useEffect(() => {
        show();
        return () => hide();
    });
};