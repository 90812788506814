import {getArrayDataContent, getDataContent} from "../utils";
import {get} from "lodash";
import {authChangeAccount} from "../actions";
import {useDispatch} from "react-redux";
import {useCrudFetch} from "./CrudHooks";
import {useGlobalParam} from "./GlobalParamsHooks";
import {CrudTypes} from "../clients";
import {useEffect} from "react";

/**
 * Representation of a single BCC Account
 */
export type Account = {
    id: string,
    name: string
};

/**
 * Representation of a single workspace
 */
export type Workspace = {
    description?: string,
    id?: string | number,
    name: string
}

/**
 * Representation of a single BCC account enriched with associated workspace data
 */
export type AccountWorkspace = Account & {
    virtualWans?: Array<Workspace>
}

/**
 * Representation of a BCC User Account Details object, as returned by list accounts APIs.
 */
export type UserAccountDetails = {
    id: string,
    email: string,
    defaultAccount: Account,
    currentAccount: Account,
    allAccounts: Array<Account>
};

/**
 * Representation of a BCC User Account Details object, as returned by list accounts APIs.
 */
export type AccountList = Array<AccountWorkspace>;

/**
 * Array returned by the useAccounts hook.
 */
export type UseAccountResponse = [
    /** array of accounts associated with the current user. */
    AccountList,
    /** the currently active account. */
    Account,
    /** the default account as set on BCC. */
    Account,
    /** callback to swap to a target account. */
    (account: Account) => void,
    /** callback to request updated account data */
    () => void
];

const sortByName = (itemA: { name?: string }, itemB: { name?: string }) => {
    const itemAName = itemA?.name?.toLowerCase() || "";
    const itemBName = itemB?.name?.toLowerCase() || "";
    if (itemBName > itemAName) {
        return -1;
    }
    return itemAName > itemBName ? 1 : 0;
};

/**
 * Hook for managing the current user's accounts.
 * @param listAccountsResource the CRUD resource to fetch the account list from. It is assumed this will return an array of accounts, and each account contains a "name" and "id" property.
 * @param userDataResource the CRUD resource to fetch portal account user info from, it's assumed this will be an object containing details about the user and their accounts
 * @param updateInPlace if true, then the whole app is not reloaded, and instead the account is swapped via background fetch requests only. Otherwise the app is reloaded after changing accounts.
 * @return
 */
export const useAccounts = (listAccountsResource?: string, userDataResource?: string, updateInPlace?: boolean): UseAccountResponse => {
    const [currentAccountId] = useGlobalParam("userData.currentAccount");
    const [userDataResponse, , fetchUserData] = useCrudFetch<UserAccountDetails>(CrudTypes.GET, userDataResource);
    const userData = getDataContent(userDataResponse);
    const [accountListResponse, , fetchAccountList] = useCrudFetch<AccountList>(CrudTypes.GET, listAccountsResource);
    const accountListData = getArrayDataContent(accountListResponse);
    const availableAccounts = (accountListData.length ? accountListData : (userData?.allAccounts || []))
        .map((account: AccountWorkspace) => ({
            ...account,
            virtualWans: account?.virtualWans && account.virtualWans.sort(sortByName)
        })).sort(sortByName);
    const dispatch = useDispatch();
    const currentAccount = availableAccounts.find((account: Account) => account.id === currentAccountId) || get(userData, "currentAccount", {});
    const swapAccount = (account: Account) => dispatch(authChangeAccount(account.id, updateInPlace));
    const defaultAccount = get(userData, "defaultAccount", {});
    // strip out any function args to prevent inadvertently changing the request params
    const refreshAccounts = () => {
        fetchAccountList();
        fetchUserData();
    };

    useEffect(() => {
        refreshAccounts();
    }, [currentAccountId]);

    return [availableAccounts, currentAccount, defaultAccount, swapAccount, refreshAccounts];
};