import React from "react";
import {UserDirectoryGoogle} from "../../UserDirectoriesTypes";
import {ConnectedForm, TextInput} from "@cuda-react/core";
import apiResources from "../../../../../../apiResources";

type UserDirectoryGoogleFormProps = {
    directory: UserDirectoryGoogle;
    create: boolean;
    id?: string;
    onSubmit: () => void;
    onCancel: () => void;
}

const i18nScope = "tesseract.identity.userDirectories.form";

export const UserDirectoryGoogleForm: React.FC<UserDirectoryGoogleFormProps> = ({
    onSubmit,
    onCancel,
    create,
    directory,
    id,
}: UserDirectoryGoogleFormProps) => (
        <ConnectedForm
            create={create}
            resource={apiResources.directories}
            params={create ? undefined : {id}}
            onSubmitSuccess={onSubmit}
            onCancel={onCancel}
            initialValues={directory}
        >
            <TextInput
                source="displayName"
                label={`${i18nScope}.displayName`}
                isRequired
            />
            <TextInput
                source="options.googleWorkspaceDomain"
                label={`${i18nScope}.google.googleWorkspaceDomain`}
                isRequired
            />
        </ConnectedForm>
    );