import {get, isEmpty} from "lodash";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {authCheck} from "../../../actions";

export interface AuthenticatedProps {
    /**
     * Parameters to pass to the AUTH_CHECK action creator
     */
    authParams?: any,
    /**
     * the content to render if user is authenticated.
     */
    children: React.ReactNode
}

/**
 * Ensures user is authenticated before displaying children.
 *
 * Children elements are not rendered until the GlobalParam state contains a userData object.
 * Additionally, it triggers an AUTH_CHECK action on mount (with provided params).
 */
export const Authenticated = ({children, authParams}: AuthenticatedProps) => {
    const authData = useSelector((state) => get(state, "GlobalParamsReducer.userData"));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(authCheck(authParams));
    }, []);

    return (
        <React.Fragment>
            {!isEmpty(authData) ? children : null}
        </React.Fragment>
    );
};

export default Authenticated;