import {Typography} from "@barracuda-internal/bds-core";
import {Trans, useTranslation} from "react-i18next";
import {DualListInput} from "@cuda-react/core";
import React, {useMemo} from "react";
import {makeStyles} from "@mui/styles";


export const useStyles = makeStyles((theme) => ({
    message: {
        margin: theme.spacing(1, 2, 2)
    },
}));

export type ResolveSeatLimitStepProp = {
    remainingSeats: number,
    resolvedUsersChoices: string[]
}

export const ResolveSeatLimitStep = ({remainingSeats, resolvedUsersChoices}: ResolveSeatLimitStepProp) => {
    const classes = useStyles();
    const [translate] = useTranslation();
    const validations = useMemo(() => ({
        validateResolvedUsers: (value: any[]) => value && value.length > remainingSeats ? translate("tesseract.endpoint.enrollEmailDialog.validate.seatLimit", {maxCount: remainingSeats, currentCount: value.length}) : undefined
    }), [remainingSeats]);

    return (
        <React.Fragment>
            <Typography className={classes.message}>
                <Trans i18nKey="tesseract.endpoint.enrollEmailDialog.resolvedUsers" values={{seats: remainingSeats}}/>
            </Typography>
            <DualListInput
                source="resolvedUsersDualList"
                choices={[{data: resolvedUsersChoices.map((item) => ({id: item, name: item})), value: {id: "", name: ""}}]}
                inputLabelProps={{
                    fullWidth: true
                }}
                initialValues={resolvedUsersChoices}
                maxSelectable={remainingSeats}
                additionalInfo="tesseract.endpoint.enrollEmailDialog.validate.seatLimit"
                validate={validations.validateResolvedUsers}
                hideAdditionalInfoOnError
            />
        </React.Fragment>
    );
};

export default ResolveSeatLimitStep;