import React, {useEffect, useState} from "react";
import mixpanel, {Config} from "mixpanel-browser";
import {MixpanelContext, MixpanelPrefixContext, useGlobalParam, useOneTrust} from "../../../hooks";

export interface MixpanelConfig {
    token?: string,
    options?: Partial<Config>,
    identifier?: (globalParams: any | undefined) => string | null | undefined
}

export interface AnalyticsProviderProps {
    children: React.ReactNode,
    mixpanelConfig?: MixpanelConfig;
}

const AnalyticsProvider = ({children, mixpanelConfig: {token, options = {}, identifier} = {}}: AnalyticsProviderProps) => {
    const [mixpanelPrefix, setMixpanelPrefix] = useState<string>();
    const [hasInitialized, setHasInitialized] = useState(false);
    const [hasIdentified, setHasIdentified] = useState(false);
    const [globalData] = useGlobalParam();
    const userIdentifier = globalData && identifier?.(globalData);
    const disabled = document.cookie.includes("DISABLE_MIXPANEL=true");
    const {allowedGroups} = useOneTrust();
    const optedIn = allowedGroups.includes("C0005");

    useEffect(() => {
        if (token && !disabled && optedIn) {
            mixpanel.init(
                token,
                options
            );
            setHasInitialized(true);
        }
    }, [token, disabled, optedIn]);

    useEffect(() => {
        if (hasInitialized) {
            if (!optedIn) {
                mixpanel.opt_out_tracking();
            } else {
                mixpanel.opt_in_tracking();
            }
        }
    }, [optedIn, hasInitialized]);

    useEffect(() => {
        hasInitialized && userIdentifier && mixpanel.identify(userIdentifier);

        // if the user identifier is null then we're not intending to identify and can continue,
        // otherwise we should keep waiting before we proceed
        (userIdentifier || userIdentifier === null) && setHasIdentified(true);
    }, [hasInitialized, userIdentifier]);

    return (
        <MixpanelContext.Provider value={hasInitialized && hasIdentified && mixpanel}>
            <MixpanelPrefixContext.Provider value={[mixpanelPrefix, setMixpanelPrefix]}>
                {children}
            </MixpanelPrefixContext.Provider>
        </MixpanelContext.Provider>
    );
};

export default AnalyticsProvider;