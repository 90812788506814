import React from "react";
import {ButtonDialog} from "@cuda-react/core";
import {Edit} from "@barracuda-internal/bds-core/dist/Icons/Core";

interface CreateEditButtonDialogProps {
    create?: boolean;
    data?: Record<string, any> | null;
    disabled?: boolean;
    disabledMessage?: string;
    label?: string;
    component: React.ComponentType<any>;
    onSuccess?: () => void;

    [key: string]: any; // You can adjust this to allow any other additional props
}

const CreateEditButtonDialog: React.FC<CreateEditButtonDialogProps> = ({
                                                                           create,
                                                                           data,
                                                                           label,
                                                                           component: Component,
                                                                           onSuccess,
                                                                           disabled,
                                                                           disabledMessage,
                                                                           ...props
                                                                       }) => (
    <ButtonDialog
        buttonText={create ? label : undefined}
        buttonIcon={create ? undefined : <Edit id="edit-rule-icon"/>}
        disabled={disabled}
        disabledMessage={disabledMessage}
        stopPropagation
    >
        {React.createElement(Component, {create, id: data?.id, onSuccess, ...props})}
    </ButtonDialog>
);

CreateEditButtonDialog.defaultProps = {
    label: "tesseract.security.addRuleButtonText"
};

export default CreateEditButtonDialog;