import React from "react";
import {UserDirectoryOkta} from "../../UserDirectoriesTypes";
import {ConnectedForm, TextInput} from "@cuda-react/core";
import apiResources from "../../../../../../apiResources";

type UserDirectoryOktaFormProps = {
    directory: UserDirectoryOkta;
    create: boolean;
    id?: string;
    onSubmit: () => void;
    onCancel: () => void;
}

const i18nScope = "tesseract.identity.userDirectories.form";

export const UserDirectoryOktaForm: React.FC<UserDirectoryOktaFormProps> = ({
    onSubmit,
    onCancel,
    create,
    directory,
    id,
}: UserDirectoryOktaFormProps) => (
        <ConnectedForm
            create={create}
            resource={apiResources.directories}
            params={create ? undefined : {id}}
            onSubmitSuccess={(response) => {
                onSubmit();
            }}
            onCancel={onCancel}
            initialValues={directory}
        >
            <TextInput
                source="displayName"
                label={`${i18nScope}.displayName`}
                isRequired
            />
            <TextInput
                source="options.oktaDomainName"
                label={`${i18nScope}.okta.domainname`}
                isRequired
            />
            <TextInput
                source="options.oktaAuthToken"
                label={`${i18nScope}.okta.authToken`}
                isRequired
            />
        </ConnectedForm>
    );