import {parse} from "query-string";
import {
    AUTH_CHANGE_ACCOUNT,
    AUTH_CHECK,
    AUTH_ERROR,
    AUTH_LOGIN,
    AUTH_LOGOUT,
    authLogout,
    DIALOG_NOTIFICATION,
    ERROR_NOTIFICATION,
    notificationHide,
    notificationShowToast,
    redirect as urlRedirect,
    saveLoginUrl,
    saveOrigin as saveOriginAction,
    TOAST_NOTIFICATION,
    updateGlobalParams
} from "../actions";
import {call, put, takeEvery, takeLeading} from "redux-saga/effects";

const getOrigin = () => {
    const reduxRouterPath = window.location.href.substring(window.location.href.indexOf("#") + 1);
    if (reduxRouterPath.startsWith("/login")) {
        const existingOrigin = parse(reduxRouterPath.substring(reduxRouterPath.indexOf("?") + 1));
        return existingOrigin && existingOrigin.origin || "/";
    }

    return reduxRouterPath;
};

const parseException = (exception) => {
    if (typeof exception === "object") {
        const {message, redirect, loginUrl, ...newParams} = exception;

        return [
            message || "cuda.auth.signInError",
            redirect,
            loginUrl,
            Object.keys(newParams).length > 0 ? newParams : undefined
        ];
    }
    return [
        (typeof exception === "string" && exception) || "cuda.auth.signInError"
    ];
};

const parseResponse = (authClientResponse) => {
    let redirectUrl, loginUrl;
    const {redirect, loginUrl: loginUrlToSave, ...newParams} = authClientResponse || {};

    if (redirect) {
        redirectUrl = redirect;
    }

    loginUrl = loginUrlToSave;

    return [
        redirectUrl,
        loginUrl,
        Object.keys(newParams).length > 0 ? newParams : undefined
    ];
};

export function* resolveFailure(errorMessage, redirect, loginUrl, newParams, saveOrigin) {
    if (newParams) {
        yield put(updateGlobalParams(newParams));
    }

    if (saveOrigin) {
        yield put(saveOriginAction(getOrigin()));
    }

    if (errorMessage) {
        yield put(notificationShowToast(errorMessage, ERROR_NOTIFICATION));
    }

    if (loginUrl || loginUrl === null) {
        yield put(saveLoginUrl(loginUrl));
    }

    if (redirect) {
        yield put(urlRedirect(redirect));
    }
}

export function* handleLogin(authClient, {type, params, payload, error, globalParams})  {
    try {
        const authResponse = yield call(authClient, type, error || params || payload, globalParams);
        const [redirect, loginUrl, newParams] = parseResponse(authResponse);

        yield put(notificationHide(DIALOG_NOTIFICATION));
        yield put(notificationHide(TOAST_NOTIFICATION));

        if (newParams) {
            yield put(updateGlobalParams(newParams));
        }

        yield put(saveLoginUrl(loginUrl));
        yield put(saveOriginAction(null));
        yield put(urlRedirect(redirect || "/"));
    } catch (exception) {
        const [message, redirect, loginUrl, newParams] = parseException(exception);
        yield call(resolveFailure, message, redirect, loginUrl || null, newParams, false);
    }
}

export function* handleLogout(authClient, {type, params, payload, error, globalParams})  {
    try {
        const authResponse = yield call(authClient, type, error || params || payload, globalParams);
        const [redirect, loginUrl, newParams] = parseResponse(authResponse);

        if (newParams) {
            yield put(updateGlobalParams(newParams));
        }

        if (loginUrl) {
            yield put(saveLoginUrl(loginUrl));
        }

        yield put(saveOriginAction(getOrigin()));
        yield put(urlRedirect(redirect || "/login"));
    } catch (exception) {
        const [, redirect, loginUrl, newParams] = parseException(exception);
        yield call(resolveFailure, undefined, redirect, loginUrl, newParams, true);
    }
}

export function* handleChangeAccount(authClient, {type, params, payload, error, globalParams})  {
    try {
        const authResponse = yield call(authClient, type, error || params || payload, globalParams);

        if (!authResponse) {
            return;
        }

        yield put(notificationHide(DIALOG_NOTIFICATION));
        yield put(notificationHide(TOAST_NOTIFICATION));

        const [redirect, loginUrl, newParams] = parseResponse(authResponse);

        if (newParams) {
            yield put(updateGlobalParams(newParams));
        }

        if (loginUrl) {
            yield put(saveLoginUrl(loginUrl));
        }

        if (redirect) {
            yield put(saveOriginAction("/"));
            yield put(saveOriginAction(getOrigin()));
            yield put(urlRedirect(redirect || "/"));
        }
    } catch (exception) {
        const [message, redirect, loginUrl, newParams] = parseException(exception);
        yield call(resolveFailure, message, redirect, loginUrl, newParams, false);
    }
}

export function* handleError(authClient, {params, payload, error, globalParams})  {
    try {
        const authResponse = yield call(authClient, AUTH_ERROR, error || params || payload, globalParams);
        const [redirect, loginUrl, newParams] = parseResponse(authResponse);

        if (newParams) {
            yield put(updateGlobalParams(newParams));
        }

        if (loginUrl) {
            yield put(saveLoginUrl(loginUrl));
        }

        if (redirect) {
            yield put(saveOriginAction(getOrigin()));
            yield put(urlRedirect(redirect));
        }
    } catch (exception) {
        const [, redirect, loginUrl, newParams] = parseException(exception);
        yield call(resolveFailure, undefined, redirect, loginUrl, newParams, true);

        if (!redirect) {
            yield put(authLogout());
        }
    }
}

export function* handleCheck(authClient, {type, params, payload, error, globalParams})  {
    try {
        const authResponse = yield call(authClient, type, error || params || payload, globalParams);
        const [redirect, loginUrl, newParams] = parseResponse(authResponse);

        if (newParams) {
            yield put(updateGlobalParams(newParams));
        }

        if (loginUrl) {
            yield put(saveLoginUrl(loginUrl));
        }

        if (redirect) {
            yield put(saveOriginAction(getOrigin()));
            yield put(urlRedirect(redirect));
        }
    } catch (exception) {
        const [, redirect, loginUrl, newParams] = parseException(exception);
        yield call(resolveFailure, undefined, redirect || "/login", loginUrl, newParams, true);
    }
}

export default (authClient) => (function* authSagas() {
    if (authClient) {
        yield takeEvery(AUTH_CHECK, handleCheck, authClient);
        yield takeEvery([AUTH_ERROR], handleError, authClient);
        yield takeLeading(AUTH_LOGOUT, handleLogout, authClient);
        yield takeLeading(AUTH_CHANGE_ACCOUNT, handleChangeAccount, authClient);
        yield takeLeading(AUTH_LOGIN, handleLogin, authClient);
    }
});