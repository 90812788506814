import React, {useCallback, useMemo} from "react";
import {
    MenuButton
} from "@cuda-react/core";

import {useTranslation} from "react-i18next";
import {UserDirectoryType} from "../UserDirectoriesTypes";

const i18nScope = "tesseract.identity.userDirectories";

interface AddUserDirectoryButtonProps {
    onSelect: (type: UserDirectoryType) => void;
    disabled?: boolean;
}

export const AddUserDirectoryButton = ({onSelect, disabled = false}: AddUserDirectoryButtonProps) => {
    const [translate] = useTranslation();

    const clickHandler = useCallback((type) => () => {
        onSelect(type);
    }, [onSelect]);

    const menuItems = useMemo<{label: string, onClick: () => void}[]>(() => [
        {
            label: translate(`${i18nScope}.form.type.entraid`),
            onClick: clickHandler('entraid'),
        },
        {
            label: translate(`${i18nScope}.form.type.google`),
            onClick: clickHandler('google'),
        },
        {
            label: translate(`${i18nScope}.form.type.okta`),
            onClick: clickHandler('okta'),
        },
        {
            label: translate(`${i18nScope}.form.type.bcc`),
            onClick: clickHandler('bcc'),
        },
        {
            label: translate(`${i18nScope}.form.type.ldap`),
            onClick: clickHandler('ldap'),
        },
    ], [translate, onSelect]);

    return (
        <MenuButton
            label="tesseract.identity.userDirectories.createDirectory"
            disabled={disabled}
            menuItems={menuItems} />
    );
};