import React, {createContext, useContext, useEffect} from "react";
import {Mixpanel} from "mixpanel-browser";

/**
 * holds a reference to the mixpanel instance
 */
export const MixpanelContext = createContext<Mixpanel | false>(false);

type Prefix = string | undefined
/**
 * the prefix of the product being used, and a setter to change the product globally
 */
export const MixpanelPrefixContext = createContext<[Prefix, React.Dispatch<React.SetStateAction<Prefix>>] | []>([]);

const PAGE_VIEW_EVENT = "Pageview";

/**
 * Enables tracking analytics on the current page
 *
 * @example Simple page view
 * // tracks the event "<PREFIX> Dashboard Pageview" when the page loads
 * useMixpanel("Dashboard");
 *
 * @example Page view with additional detail
 * // tracks the event "<PREFIX> Dashboard Pageview" when the page loads, passing the additional details and waiting for model data to be present
 * useMixpanel("Dashboard", !!model, {model});
 *
 * @example Custom onLoad event name w/additional manual trigger
 * // tracks the event "<PREFIX> Create Site Start" when the page loads
 * const registerAction = useMixpanel("Create Site", "Start", {type: "S"});
 * // tracks another event "<PREFIX> Create Site Success" when called, along with the additional data
 * registerAction("Success", {lans: 5})
 */
export const useMixpanel = (
    /**
     * Name of the page being viewed
     */
    pageName: string,
    /**
     * Name of the default action to register when the component is first rendered.
     * if false it will not register on initialisation,
     * if true it will use the default page view event.
     *
     * this allows you to wait for a condition to be true before tracking an event,
     * such as waiting for additionalDetails to load,
     * by changing from false to true when it's ready
     */
    onLoadAction: boolean | string = PAGE_VIEW_EVENT,
    /**
     * extra data to record with the on load action
     */
    additionalDetails: Record<string, string | number | boolean> = {}
) => {
    const mixpanel = useContext(MixpanelContext);
    const [prefix] = useContext(MixpanelPrefixContext);

    const getTrackingEvent = (action: string) => (prefix ? prefix + " " : "") + `${pageName} ${action}`;

    const registerAction = (
        /**
         * Name of the action to register
         */
        action: string,
        /**
         * additional details for this action, merged with the additionalDetails provided to the hook
         */
        customAdditionalDetails: Record<string, string | number | boolean> = {}
    ) => {
        const trackingEvent = getTrackingEvent(action);
        if (process.env.NODE_ENV === "development") {
            // eslint-disable-next-line no-console
            console.info("[mixpanel]", trackingEvent, customAdditionalDetails);
        }
        mixpanel && mixpanel.track(
            trackingEvent,
            customAdditionalDetails
        );
    };

    useEffect(() => {
        if (onLoadAction && !!mixpanel && prefix) {
            registerAction(
                onLoadAction === true ? PAGE_VIEW_EVENT : onLoadAction,
                additionalDetails
            );
        }
        // mixpanel and prefix may not be defined if they haven't been initialised yet,
        //  make sure we don't miss the page load event if they're not ready
    }, [!!mixpanel, prefix, onLoadAction]);

    return registerAction;
};

/**
 * Allows setting the mixpanel prefix for the current product
 *
 * @param prefix the desired prefix
 */
export const useMixpanelPrefix = (prefix: string) => {
    const [, setPrefix] = useContext(MixpanelPrefixContext);

    useEffect(() => {
        prefix && setPrefix?.(prefix);
    }, [prefix]);
};