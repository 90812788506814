import React from "react";
import {useFormState, useWatch} from "react-hook-form";
import {Typography} from "@barracuda-internal/bds-core";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";

export const useStyles = makeStyles((theme) => ({
    message: {
        padding: theme.spacing(2, 12.25, 2, 4.75)
    }
}));

const WanEditWarning = () => {
    const classes = useStyles();
    const [translate] = useTranslation();

    const {defaultValues, dirtyFields} = useFormState();
    const mode = useWatch({name: "mode"}) || [];
    const addresses = useWatch({name: "addresses"}) || [];

    return (
        /* the first condition checks that the form has been opened in edit mode */
        defaultValues?.id && !(["wwan", "expressRoute", "bridge"].includes(defaultValues?.mode)) && dirtyFields?.mode && (["wwan", "expressRoute", "bridge"].includes(mode)) ?
            <Typography className={classes.message}>
                {translate("tesseract.appliances.settings.wan.warning")}
            </Typography>
        : defaultValues?.id && dirtyFields?.addresses && !defaultValues?.addresses.every((address: string) => addresses.includes(address)) ?
            <Typography className={classes.message}>
                {translate("tesseract.appliances.settings.wan.addressWarning")}
            </Typography>
        : null
    );
};

export default WanEditWarning;