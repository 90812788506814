import {
    BANNER_NOTIFICATION,
    ERROR_NOTIFICATION,
    useCrudProps,
    useDeepCompareEffect,
    useNotifications,
} from "@cuda-react/core";
import apiResources from "../apiResources";


export default () => {
    const [notification] = useNotifications(BANNER_NOTIFICATION, "tesseract.statusBanner.pamBandwidthCapacity", ERROR_NOTIFICATION);
    const bandwidthData = useCrudProps(apiResources.managedBandwidthsUsage)[0]?.data;

    useDeepCompareEffect(() => {
        bandwidthData?.available < 0 && notification();
    }, [bandwidthData]);
};