import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {set} from "lodash";
import React from "react";
import {BaseFieldProps} from "../../fields";
import {createStyles} from "@mui/styles";

const styles = createStyles({
    readOnly: {
        display: "inline-block",
        padding: "10px 0"
    }
});
const useStyles = makeOverrideableStyles("ReadOnlyInput", styles);

export interface ReadOnlyProps<FieldComponent extends React.ElementType<BaseFieldProps>> extends StyledComponentProps<typeof styles> {
    /**
     * it will create an input of this type.
     */
    field: FieldComponent,
    /**
     * passed to the ReadOnly children component as props.
     */
    options: Partial<React.ComponentProps<FieldComponent>>,
    /**
     * if rendered within a [Form](/?path=/docs/core-components-forms-form--form)/[TabbedForm](/?path=/docs/core-components-forms-tabbedform--tabbed-form)
     * or [Wizard](/?path=/docs/core-components-wizard-wizard--wizard) this value is treated as a dot-notation path to associate the input with a field in the form data.
     */
    source: BaseFieldProps["source"],
    /**
     * current value of the input.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    value: any
}

const ReadOnly = <FieldComponent extends React.ElementType<BaseFieldProps>>(props: ReadOnlyProps<FieldComponent>) => {
    const {
        options,
        source = "",
        field: FieldToRender,
        value
    } = props;

    const classes = useStyles(props);

    return React.createElement(FieldToRender, {
        source,
        data: set({}, source, value),
        className: classes.readOnly,
        ...options
    });
};

export default ReadOnly;