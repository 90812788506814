import React, {useCallback} from 'react';
import {
    CrudTypes,
    INFO_NOTIFICATION,
    MenuButton,
    TOAST_NOTIFICATION,
    useCrudFetch,
    useMixpanel,
    useNotifications
} from '@cuda-react/core';
import apiResources from '../../../../../apiResources';
import {useTranslation} from 'react-i18next';

const i18nScope = "tesseract.identity.userDirectories";

export const UserDirectoryMenu = ({data}: { data?: { id: string }}) => {
    const registerAction = useMixpanel('User Directories', false);
    const [showNotification] = useNotifications(TOAST_NOTIFICATION, "", INFO_NOTIFICATION, {duration: 5000});
    const [,, performSync] = useCrudFetch(CrudTypes.CREATE, apiResources.directoriesSync);

    const [translate] = useTranslation();

    const handleSync = useCallback(() => {
        if(!data) {
            return;
        }

        performSync({id: data.id});
        showNotification(TOAST_NOTIFICATION, translate(`${i18nScope}.syncedNotification`));
        registerAction('manually synced');
    }, [data, performSync, showNotification, registerAction, translate]);


    return (
        <MenuButton
            anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            transformOrigin={{horizontal: 'left', vertical: 'top'}}
            menuItems={[
                {
                    label: 'tesseract.identity.userDirectories.sync',
                    onClick: handleSync
                },
            ]}
            iconButton
        />
    );
};