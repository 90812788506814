import React, {useRef, useState} from "react";
import {
    ConnectedTableRefresh,
    INFO_NOTIFICATION,
    MenuButton,
    TOAST_NOTIFICATION,
    useMixpanel,
    useNotifications
} from "@cuda-react/core";
import CreateEnrollEmailDialog from "./enroll/CreateEnrollEmailDialog";
import CopyToClipboardButton from "../../../components/CopyToClipboardButton";
import {Dialog} from "@barracuda-internal/bds-core";

interface UsersMenuProps {
    data?: {
        id?: any;
        email?: string;
        uuid?: string;
    };
    enrollmentUrl?: string;
}

const UsersMenu: React.FC<UsersMenuProps> = ({data, enrollmentUrl}) => {
    const registerAction = useMixpanel("Endpoint Users", false);
    const [showCopyLinkNotification] = useNotifications(TOAST_NOTIFICATION, "tesseract.users.table.enrollmentLinkCopied", INFO_NOTIFICATION, {duration: 5000});
    const [resendOpen, setResendOpen] = useState(false);
    const copyOnClickRef = useRef<ConnectedTableRefresh>(null);

    return (
        <React.Fragment>
            <MenuButton
                anchorOrigin={{horizontal: "left", vertical: "top"}}
                transformOrigin={{horizontal: "left", vertical: "top"}}
                menuItems={[
                    {
                        label: "tesseract.users.table.resendInvite",
                        onClick: () => setResendOpen(true)
                    },
                    {
                        label: "tesseract.users.table.enrollmentLink",
                        onClick: () => {
                            registerAction("Copy Link");
                            copyOnClickRef?.current?.();
                            showCopyLinkNotification();
                        }
                    }
                ]}
                iconButton
            />
            <Dialog open={resendOpen} maxWidth={false} onClose={() => (setResendOpen(false))}>
                <CreateEnrollEmailDialog id={data?.id} email={data?.email} onClose={() => (setResendOpen(false))}/>
            </Dialog>
            <CopyToClipboardButton
                value={`${document.location.origin}/zerotrust/enroll/?uuid=${data?.uuid}&endpoint=https://${enrollmentUrl}`}
                onClickRef={copyOnClickRef}
                hideButton
            />
        </React.Fragment>
    );
};

export default UsersMenu;