export default {
  translation: {
    stratosReporting: {
      resourceStatisticsCard: {
        overview: "Gesamt"
      },
      domainCard: {
        title: "Domains"
      },
      dualTopBarBaseCard: {
        title: "Domains",
        overview: "Insgesamt",
        totalAllowedSessions: "Zulässige Sitzungen ",
        totalBlockedSessions: "Gesperrte Sitzungen ",
        allowed: "Zulässig",
        blocked: "Gesperrt"
      },
      topUrlCard: {
        title: "URL-Kategorien"
      },
      malwareCard: {
        title: "Erweiterter Bedrohungs-Schutz",
        hits: "{{formattedCount}} Treffer",
        hits_one: "{{formattedCount}} Treffer",
        hits_other: "{{formattedCount}} Treffer"
      },
      ipsIncidentCard: {
        title: "IPS-Vorfälle",
        sessions: "{{sessions}} Treffer",
        sessions_one: "{{sessions}} Treffer",
        sessions_other: "{{sessions}} Treffer"
      },
      affectedUsers: {
        title: "Betroffene Benutzer"
      },
      sdwanStatusCard: {
        title: "SD-WAN Status",
        overviewTitle: "Gesamt",
        healthy: "Gesund",
        degraded: "Eingeschränkt",
        down: "Unterbrochen",
        tableTitle: "SD-WAN Status Top 10 Geräte",
        name: "Name",
        location: "Standort",
        type: "Typ"
      },
      totalChart: {
        total: "Insgesamt"
      },
      sdwanBandwidthCard: {
        tableTitle: "SD-WAN Bandbreite Top 5",
        title: "SD-WAN Bandbreite Zusammenfassung"
      },
      sdwanBandwidthChartCard: {
        title: "SD-WAN Bandbreite",
        topTunnels: "Top {{length}} Tunnels"
      },
      osStatusCard: {
        title: "Betriebssysteme",
        others: "Andere",
        name: "Name",
        location: "Standort",
        type: "Typ",
        ip: "IP-Adresse",
        linux: "Linux",
        mac: "Mac OSX",
        windows: "Windows",
        other: "anderes",
        android: "Android",
        ios: "iOS"
      },
      applianceMapCard: {
        title: "Übersicht der Appliances"
      },
      baseBarCard: {
        total: "von {{total}}"
      },
      topApplicationCard: {
        title: "Top Anwendungen",
        topApplications: "Top {{length}} Anwendungen"
      },
      sdwanSummaryCard: {
        title: "SD-WAN Zusammenfassung",
        topAppliances: "Top {{length}} Appliances",
        inbound: "eingehend",
        outbound: "ausgehend"
      },
      topAllowedCategoriesSessions: {
        title: "Top zulässige App-Kategorien <0>(pro Sitzung)</0>",
        menuEntry: "Top zulässige App-Kategorien (pro Sitzung)",
        footerKey: "Top {{length}}",
        sessions: "{{formattedCount}} Sitzungen",
        sessions_one: "{{formattedCount}} Sitzung",
        sessions_other: "{{formattedCount}} Sitzungen"
      },
      topAllowedCategoriesTraffic: {
        title: "Top zulässige App-Kategorien <0>(pro Traffic)</0>",
        menuEntry: "Top zulässige App-Kategorien (pro Traffic)",
        footerKey: "Top {{length}}"
      },
      blockedApplicationCategories: {
        title: "Top gesperrte App-Kategorien",
        footerKey: "Top {{length}}"
      },
      allowedApplicationsSessions: {
        title: "Top zulässige Apps <0>(pro Sitzung)</0>",
        menuEntry: "Top zulässige Apps (pro Sitzung)",
        sessions: "{{formattedCount}} Sitzungen",
        footerKey: "Top {{length}}",
        sessions_one: "{{formattedCount}} Sitzung",
        sessions_other: "{{formattedCount}} Sitzungen"
      },
      allowedApplicationsTraffic: {
        title: "Top zulässige Apps <0>(pro Traffic)</0>",
        menuEntry: "Top zulässige Apps (pro Traffic)",
        footerKey: "Top {{length}}"
      },
      blockedApplications: {
        title: "Top gesperrte Apps",
        sessions: "{{formattedCount}} Sitzungen",
        footerKey: "Top {{length}}",
        sessions_one: "{{formattedCount}} Sitzung",
        sessions_other: "{{formattedCount}} Sitzungen"
      },
      applicationRisk: {
        mediumHigh: "Mittel -\nHoch",
        medium: "Mittel",
        lowMedium: "Gering - Mittel",
        low: "Gering",
        high: "Hoch",
        title: "App-Risiko",
        risk: "Risiko"
      },
      highRiskApplicationsCard: {
        title: "Apps mit hohem Risiko",
        top: "Top {{length}}"
      },
      topWebRequest: {
        title: "Web Aufrufe",
        tableTitle: "Top Web Aufrufe",
        total: "Gesamt",
        allowed: "erlaubt",
        blocked: "blockiert",
        type: "Typ",
        location: "Standort",
        name: "Name"
      },
      storageCard: {
        title: "Speicherüberblick",
        use: "<0>{{spaceUsed}}GB</0> belegt von {{totalSpace}}GB",
        retention: "Vorratsdatensspeicherung f. ca. 3 Tage",
        name: "Name",
        lastChange: "Zuletzt geändert",
        fileSize: "Detaigrösse",
        storageLow: "Der Speicherplatz wird knapp!",
        upgradeStorageNow: "Bitte Speicherplatz erweitern",
        tableTitle: "Top Appliances die Speicher belegen"
      },
      topBlockedUserCard: {
        title: "Top gesperrte Benutzer",
        topUsers: "Top {{length}} Benutzer",
        total: "out of {{total}}",
        hits: "{{formattedCount}} Treffer",
        hits_one: "{{formattedCount}} Treffer",
        hits_other: "{{formattedCount}} Treffer"
      },
      topBlockedUrlCategoriesCard: {
        title: "Top gesperrte URL-Kategorien",
        topUrlCategories: "Top {{length}} gesperrte URL-Kategorien",
        total: "von {{total}}",
        sessions: "{{formattedCount}} Sitzungen",
        sessions_one: "{{formattedCount}} Sitzung",
        sessions_other: "{{formattedCount}} Sitzungen"
      },
      applicationCategories: {
        title: "App-Kategorien"
      },
      topApplications: {
        title: "Apps"
      },
      topAllowedUrlCategoriesCard: {
        titlePerSession: "Top zulässige URL-Kategorien <0>(pro Sitzung)</0>",
        titlePerTraffic: "Top zulässige URL-Kategorien <0>(pro Traffic)</0>",
        menuEntryPerSession: "Top zulässige URL-Kategorien (pro Sitzung)",
        menuEntryPerTraffic: "Top zulässige URL-Kategorien (pro Traffic)",
        topAllowedUrlCategories: "Top {{length}} zulässige URL-Kategorien",
        total: "von {{total}}",
        sessions: "{{formattedCount}} Sitzungen",
        sessions_one: "{{formattedCount}} Sitzung",
        sessions_other: "{{formattedCount}} Sitzungen"
      },
      topAllowedDomainsCard: {
        titlePerSession: "Top zulässige Domains <0>(pro Sitzung)</0>",
        titlePerTraffic: "Top zulässige Domains <0>(pro Traffic)</0>",
        menuEntryPerSession: "Top zulässige Domains (pro Sitzung)",
        menuEntryPerTraffic: "Top zulässige Domains (pro Traffic)",
        topAllowedDomains: "Top {{length}} zulässige Domains",
        total: "von {{total}}",
        sessions: "{{formattedCount}} Sitzungen",
        sessions_one: "{{formattedCount}} Sitzung",
        sessions_other: "{{formattedCount}} Sitzungen"
      },
      topBlockedDomainsCard: {
        title: "Top gesperrte Domains",
        topBlockedDomains: "Top {{length}} gesperrte Domains",
        total: "von {{total}}",
        sessions: "{{formattedCount}} Sitzungen",
        sessions_one: "{{formattedCount}} Sitzung",
        sessions_other: "{{formattedCount}} Sitzungen"
      },
      topUsersCard: {
        title: "Top Benutzer",
        topUsers: "Top {{length}} Benutzer",
        total: "von {{total}}"
      },
      sdwanHeatMap: {
        title: "SD-WAN Übersichtskarte"
      },
      legendFooter: {
        online: "erreichbar",
        offline: "nicht erreichbar",
        degraded: "degradiert"
      },
      applianceStatus: {
        title: "Appliance Status",
        topUsers: "Top {{length}} Benutzer",
        total: "von insgesamt {{total}}"
      },
      previewDialog: {
        addWidgetButton: "Widget hinzufügen",
        title: "Anzeigekomponente hinzufügen",
        card: "Anzeigekomponente",
        size: "Größe",
        cancel: "Abbrechen",
        add: "Hinzufügen",
        small: "Klein",
        medium: "Mittel",
        large: "Gross",
        addWidgetCard: "Klicken, um Widget hinzuzufügen!",
        ok: "OK",
        editTitle: "Widget bearbeiten"
      },
      reportTable: {
        title: "Alle Reports",
        type: "Typ",
        name: "Name",
        period: "Zeitperiode",
        devices: "Geräte",
        schedule: "Zeitplan",
        owner: "Eigentümer"
      },
      reportDialog: {
        create: {
          title: "Report erstellen",
          button: "Erstellen"
        },
        edit: {
          title: "Report ändern",
          button: "Ändern"
        },
        reportData: {
          label: "Report Daten",
          application: {
            name: "Anwendung"
          },
          protocol: {
            name: "Protokoll"
          },
          applicationRule: {
            name: "Anwendungs Regel"
          },
          contentType: {
            name: "Art des Inhalts"
          },
          outputInterface: {
            name: "Ausgabe Schnittstelle"
          },
          destination: {
            name: "Ziel"
          }
        },
        datasource: {
          label: "Datenherkunft",
          activity: {
            name: "Aktivitäten"
          },
          threat: {
            name: "Bedrohungen"
          },
          webFilter: {
            name: "Web-Filter"
          },
          sdwanTunnels: {
            name: "SD-WAN Verbindungen"
          },
          sdwanTunnelsAppProtocol: {
            name: "SD-WAN Verbindungen, Anwendungen und Protokolle"
          }
        },
        output: {
          label: "Ausgabe",
          outputFormat: {
            label: "Ausgabeformat"
          },
          deliveryOptions: {
            label: "Ausgabeoptionen",
            email: "E-Mail",
            fileDownload: "Datei Download"
          },
          recipients: {
            label: "Empfänger"
          },
          sizeLimit: {
            label: "Grössenlimitierung"
          },
          frequency: {
            label: "Häufigkeit",
            once: "Einmalig",
            weekly: "Jede Woche",
            monthly: "Jeden Monat"
          },
          status: {
            label: "Status",
            enabled: "Aktiviert",
            disabled: "Deaktiviert"
          }
        },
        advanced: {
          label: "Erweitert",
          destination: {
            label: "Ziel",
            domain: "Domänen",
            category: "Kategorie"
          },
          ipAnonymize: {
            label: "IP Anonymisieren"
          },
          serialNumberBucketing: {
            label: "Seriennummern zusammenfassen"
          },
          domains: {
            label: "Domänen"
          },
          exludeTimeframe: {
            label: "Ausgeschlossene Zeitspanne"
          },
          action: {
            label: "Aktion"
          },
          chartType: {
            label: "Grafiktyp",
            horizontalBar: "Horizontales Balkendiagramm",
            verticalBar: "Vertikales Balkendiagramm",
            pieChart: "Tortendiagramm"
          },
          ddLimitLevels: {
            label: "Drill-Down Limitierungen"
          }
        },
        result: {
          label: "Ergebnis"
        },
        success: "Erfolgreich erstellt"
      },
      userEnrollment: {
        title: "Benutzer",
        enrolledUsers: "Benutzer",
        enrolledDevices: "Geräte",
        pendingEnrollment: "Ausstehend",
        name: "Name",
        location: "Standort",
        device: "Gerätetyp"
      },
      licensing: {
        title: "Lizensierung",
        bandwidth: "Bandbreite",
        bandwidthUsageMedium: "{{remain}} Gbit/s verfügbar (von {{total}} Gbit/s)",
        bandwidthUsageLarge: "{{used}} verbraucht (von {{total}} GB/s)",
        bandwidthExpire: "Läuft ab in {{daysLeft}} Tagen",
        users: "Benutzer",
        usersUsageMedium: "{{remain}} Benutzer verfügbar (von {{total}} Benutzern)",
        usersUsageLarge: "{{used}} Benutzer vergeben (von {{total}} Benutzern)",
        usersExpire: "{{remain}} Benutzer verfügbar",
        storage: "Speicher",
        storageUsageMedium: "{{remain}} GB verfügbar (von {{total}} GB)",
        storageUsageLarge: "{{used}} GB/s benutzt (von {{total}} GB/s)",
        storageRetention: "Max. Datenspeicherung: ca. {{remain}} Tage",
        storageExpire: "Läuft aus in {{daysLeft}} Tagen",
        appliances: "Appliances",
        appliancesUsageMedium: "Noch {{remain}} Tage verfügbar (von {{total}} Tagen)",
        appliancesUsageLarge: "{{used}} Tage benutze (von {{total}} Tagen)",
        appliancesExpire: "Läuft ab in {{daysLeft}} Tagen",
        percentage: "{{percentage}}"
      },
      dashboardPage: {
        done: "Erledigt",
        cancel: "Abbruch",
        editDashboard: "Dashboard ändern",
        emptyMessage: "Dieses Dashboard enthält noch keine Komponenten.",
        addMessage: "Komponenten hinzufügen indem Sie Zufügen clicken.",
        lastDay: "Letzte 24 Stunden",
        lastWeek: "Letzte Woche",
        lastMonth: "Letzter Monat",
        configureDashboard: "Dieses Dashboard einrichten!",
        addDashboard: "Dashboard erstellen",
        name: "Name",
        template: "Vorlage",
        reset: "Dashboard zurücksetzen",
        rename: "Dashboard umbenennen",
        delete: "Dashboard löschen",
        confirmDeletion: "Möchten Sie wirklich das Dashboard '{{name}}' löschen?",
        actions: "Optionen",
        add: "Dashboard hinzufügen",
        resetToTemplate: "Auf Template zurücksetzen",
        validateName: "Darf nur alphanumerische Zeichen oder Leerzeichen enthalten.",
        changeDashboardMessage: "Sind Sie sicher, dass Sie Ihre Änderungen nicht speichern wollen?",
        uniqueNameError: "Muss eindeutig sein",
        resetToDefault: "Möchten Sie das Dashboard auf seinen Standardzustand zurücksetzen?",
        resetToTemplateDescription: "Ihr Dashboard wieder auf die ursprünglichen Widgets und das Layout der gewählten Vorlage zurücksetzen?",
        options: "Optionen",
        maxNumberOfDashboardReached: "Es können keine weiteren Dashboards erzeugt werden, Maximum von {{maxDashboardsAmount}} erreicht"
      },
      noData: {
        message: "Keine Daten für dieses Widget!"
      },
      barGrid: {
        noData: "Keine passenden Einträge gefunden!"
      },
      geoCard: {
        sourceTopCountries: {
          title: "Top Geo Quell-Länder"
        },
        destinationTopCountries: {
          title: "Top Geo Ziel-Länder"
        }
      },
      ZTNAResources: {
        title: "Ressourcen",
        others: "Sonstige",
        total: "Insgesamt verfügbar",
        custom: "Benutzerdefiniert",
        inUse: "Wird verwendet",
        tableName: "Access-Richtlinien",
        table: {
          name: "Name",
          description: "Beschreibung",
          users: "Benutzer",
          groups: "Gruppen",
          resources: "Ressourcen",
          devicePosture: "Device Posture"
        }
      },
      ZTNAAgentInUse: {
        title: "Meistverwendete Betriebssysteme für Agents",
        total: "Insgesamt",
        windows: "Windows",
        macos: "macOS",
        android: "Android",
        ios: "iOS",
        linux: "Linux",
        tableName: "Meistverwendete Betriebssysteme",
        devices: "{{value}} Endgerät",
        devices_plural: "{{value}} Endgeräte"
      },
      ZTNADeviceLocation: {
        title: "Endgeräte-Karte",
        devices: "Endgeräte",
        noData: "Kein Gerät gefunden.",
        user: "Benutzer",
        brand: "Endgeräte-Marke",
        model: "Endgeräte-Modell",
        hardwareModel: "Hardwaremodell",
        operatingSystem: "Betriebssystem",
        operatingSystemVersion: "Betriebssystem Version",
        agentVersion: "Agent Version",
        enrollmentDate: "Anmeldedatum",
        country: "Land",
        noCountry: "Kein Standort"
      },
      ZTNAUserGeoLocation: {
        title: "Geo-Standort des Benutzers",
        nonRoutableOrIP: "Nicht routingfähige oder private IP-Adressen",
        devices: "{{value}} Endgerät",
        devices_plural: "{{value}} Endgeräte"
      },
      userStatusCard: {
        title: "Benutzer",
        total: "Insgesamt",
        enrolled: "Registriert",
        pending: "Ausstehend",
        tableTitle: "Top-Benutzer (nach Anzahl der Geräte)",
        name: "Name",
        email: "E-Mail",
        deviceCount: "Anzahl der Endgeräte",
        enrollmentCompleted: {
          label: "Registrierung abgeschlossen",
          yes: "Registrierung abgeschlossen",
          no: "Registrierung ausstehend"
        }
      },
      userSummaryCard: {
        title: "Zusammenfassung",
        fullName: "Vollständiger Name",
        email: "E-Mail",
        deviceCount: "Anzahl der Endgeräte",
        enrollmentCompleted: "Anmeldung abgeschlossen"
      },
      userDevicesCard: {
        title: "Endgeräte",
        table: {
          userFullName: "Vollständiger Name",
          userEmail: "E-Mail",
          brand: "Endgeräte-Marke",
          hardwareModel: "Endgeräte-Modell",
          operatingSystem: "Betriebssystem",
          operatingSystemVersion: "Betriebssystem Version",
          enrollmentDate: "Anmeldungsdatum"
        }
      },
      accessDevicesAgentVersionCard: {
        title: "Verwendete Agent Version",
        total: "Insgesamt",
        version: "Version {{version}}",
        older: "Älter",
        tableTitle: "Verwendete Top-Versionen",
        devices: "{{value}} Endgerät",
        devices_plural: "{{value}} Endgeräte"
      },
      categories: {
        ztna: "Zero Trust Access",
        security: "Sicherheit",
        webFilter: "Web-Filter"
      }
    }
  }
} as const;