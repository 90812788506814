import {createStyles} from "@mui/styles";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {Trans, useTranslation} from "react-i18next";
import {get} from "lodash";
import {useCrudFetch} from "../../../hooks";
import {CrudParams, CrudTypes} from "../../../clients";
import React from "react";
import DialogBody from "../DialogBody/DialogBody";
import Container from "@mui/material/Container";
import {Button, Typography} from "@barracuda-internal/bds-core";
import Toolbar from "../../layout/Toolbar/Toolbar";
import CircularProgress from "@mui/material/CircularProgress";

const styles = createStyles({
    body: {
        textAlign: "center",
        padding: 16
    },
    multiLineBody: {
        textAlign: "left",
        padding: 16
    }
});
const useStylesContent = makeOverrideableStyles("DeleteDialogContent", styles);

export interface DeleteDialogContentProps extends StyledComponentProps<typeof styles> {
    /**
     * data associated with the resource to be deleted. Is used to fetch the value (for the [CRUD](/?path=/docs/cudareactapp-crud--page) request) and text to display in the dialog.
     *
     * Data is automatically provided by [ActionButtonsField](/?path=/docs/core-components-fields-actionbuttonsfield--action-buttons-field) when used in a
     * [ConnectedTable](/?path=/docs/core-components-table-connectedtable--connected-table).
     */
    data?: any,
    /**
     * Set this to true if the dialog should stay open after deletion error
     */
    dontCloseOnError?: boolean,
    /**
     * The  message to display in the body of the dialog. The value collected from the data object by "optionText" is passed to translator, and is available by via "{{name}}" placeholder.
     */
    message: React.ReactNode,
    /**
     * callback called when dialog is confirmed. If this is provided, the dialog will not perform a [CRUD](/?path=/docs/cudareactapp-crud--page) request, and instead this
     * callback will be called with the [CRUD](/?path=/docs/cudareactapp-crud--page) params that would have been used. Then the dialog is closed.
     *
     * @function
     * @param {object} params the params as they would have been passed to the [CRUD](/?path=/docs/cudareactapp-crud--page) request.
     */
    onDelete?: (params: CrudParams) => void,
    /**
     * callback called when dialog is closed.
     *
     * @function
     */
    onClose?: () => void,
    /**
     * callback called when dialog is confirmed. Called after onDelete or the delete request.
     *
     * @function
     */
    onSuccess?: (result?: any, data?: any) => void,
    /**
     * a dot-notation path to value within the data prop object. Is passed into the [CRUD](/?path=/docs/cudareactapp-crud--page) request params as the value of "id".
     */
    optionValue: string,
    /**
     * a dot-notation path to a value within the data prop object that should be passed to the translation message. The value found is passed in the translation as "name".
     */
    optionText: string,
    /**
     * the [CRUD](/?path=/docs/cudareactapp-crud--page) resource to call to perform the delete.
     */
    resource?: string,
    /**
     * If true, the "stopPropagation" action on each onClick event on the button is called.
     */
    stopPropagation?: boolean,
    /**
     * The title message to display on the dialog. The value collected from the data object by "optionText" is passed to translator, and is available by via "{{name}}" placeholder.
     */
    title: string,
    /**
     * Whether to show a multi line message
     */
    multiline?: boolean
}

const DeleteDialogContent = (props: DeleteDialogContentProps) => {
    const {optionValue, optionText, data, resource, title, message, onClose, onDelete, onSuccess, dontCloseOnError, multiline} = props;
    const classes = useStylesContent(props);
    const [translate] = useTranslation();
    const resourceId = data && get(data, optionValue);
    const resourceName = data && get(data, optionText);
    const customDelete = onDelete ? (params: CrudParams) => {
        onDelete(params);
        onSuccess && onSuccess();
        onClose?.();
    } : undefined;
    const [, deleting, performDelete] = useCrudFetch(CrudTypes.DELETE, resource, {id: resourceId});

    return (
        <React.Fragment>
            <DialogBody
                title={translate(title, {name: resourceName})}
            >
                <Container className={multiline ? classes.multiLineBody : classes.body}>
                    {multiline ?
                        <Typography variant="body1">
                            {typeof message === "string" ?
                                <Trans i18nKey={message} values={{name: resourceName}}><br/></Trans> : message}
                        </Typography> :
                        <Typography variant="body1">
                            {typeof message === "string" ? translate(message, {name: resourceName}) : message}
                        </Typography>
                    }
                </Container>
            </DialogBody>
            <Toolbar>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={(deleting ? (
                        <CircularProgress id="cuda-loading-spinner" size={16} thickness={2}/>
                    ) : undefined)}
                    disabled={deleting}
                    onClick={() => {
                        if (customDelete) {
                            customDelete({id: resourceId});
                        } else {
                            return performDelete({id: resourceId}).then((result) => {
                                if (!result.error) {
                                    onSuccess && onSuccess(result, data);
                                    onClose?.();
                                } else if (!dontCloseOnError) {
                                    onClose?.();
                                }
                            });
                        }
                    }}
                >
                    {translate("cuda.delete.ok")}
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    disabled={deleting}
                    onClick={onClose}
                >
                    {translate("cuda.delete.cancel")}
                </Button>
            </Toolbar>
        </React.Fragment>
    );
};

export default DeleteDialogContent;