import {pick} from "lodash";

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem("appState");
        return serializedState ? JSON.parse(serializedState) : undefined;
    } catch (err) {
        return undefined;
    }
};

export const saveState = (state: any) => {
    try {
        localStorage.setItem("appState", JSON.stringify(state));
    } catch (err) {
        // Ignore write errors.
    }
};

export const persistStore = (store: any, reducerNames: string[] = []) => {
    store.subscribe(() => {
        const currentState = store.getState();
        saveState(pick(currentState, reducerNames));
    });
};